import React, { lazy, useContext, useEffect, useState } from "react";
import { ADD_HARDWARE_CASE_COL, ADD_HARDWARE_CSP_COL } from "../../constants/AddHardwareConstants";
import { AddHardwareModal } from "./AddHardwareModal";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CompactCol, CustomSLButton, FlexColumn, FlexRow, Spacer, UltraMediumText } from "../../components/core/utility";
import DeleteHardwareModal from "./DeleteHardwareModal";
import { ValidateAddress } from "./ValidateAddress";
import { Icon } from "@vds/icons";
import edit from "../../assets/icons/edit.png";
import { C4SContext } from "../../context/c4sContext";
import { CustomDropdown, CustomInput } from "./AddAVehicle";
import _ from "lodash";
import { Loader } from "@vds/loaders";
import { saveHardware } from "../../services/C4S Decommission - Replacement/saveHardware";
import { SLNotification } from "../../components/core/utility";
import { generate } from "shortid";
import { getFullName, redirectToPrevPage, TYPE_DROPDOWN_VALUES } from "./utils";
import { updateHardware } from "../../services/C4S Decommission - Replacement/updateHardware";
import SaveHardwareModal from "./SaveHardwareModal";
import { Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout } from "../../components/core/Utility/index";
import { DropdownSelect } from "@vds/selects";
import { DISTRIBUTION_CHANNEL_DROPDOWN_VALUES, DIVISION_DROPDOWN_VALUES } from "../../constants/C4SDecomConstants";
import useOutsideClick from "../../customHook/use-outside-click";

export const salesOrgList = {
  UKR: [
    { salesOrgId: "SO_1101", salesOrgName: "VZ Connect UK Ltd" },
    { salesOrgId: "SO_4203", salesOrgName: "VZ Connect Ireland" },
  ],
  IRL: [{ salesOrgId: "SO_4211", salesOrgName: "VZC Ireland Limited" }],
  FRA: [{ salesOrgId: "SO_1201", salesOrgName: "VZC France SAS" }],
  NLD: [{ salesOrgId: "SO_3701", salesOrgName: "VZC Netherlands" }],
  BEL: [{ salesOrgId: "SO_3701", salesOrgName: "VZC Netherlands" }],
  DEU: [{ salesOrgId: "SO_1001", salesOrgName: "VZ Connect Germ GmbH" }],
  AUT: [{ salesOrgId: "SO_1001", salesOrgName: "VZ Connect Germ GmbH" }],
  CAN: [{ salesOrgId: "SO_2901", salesOrgName: "VZ Connect CanadaInc" }],
  USA: [{ salesOrgId: "SO_9001", salesOrgName: "VZC Fleet USA LLC" }],
  MEX: [{ salesOrgId: "SO_5501", salesOrgName: "MX Mexico" }],
  AUS: [{ salesOrgId: "SO_3001", salesOrgName: "Verizon Connect Aust" }],
  NZL: [{ salesOrgId: "SO_6901", salesOrgName: "VZ Connect NZ" }],
  GBR: [{ salesOrgId: "SO_1101", salesOrgName: "VZC UK Limited" }],
};

const salesOrgBasedCurrency = {
  SO_3001: "AUD",
  SO_6901: "NZD",
  SO_1001: "EUR",
  SO_1201: "EUR",
  SO_4203: "EUR",
  SO_4211: "EUR",
  SO_3701: "EUR",
  SO_1101: "GBP",
  SO_2901: "CAD",
  SO_9001: "USD",
  SO_5501: "MXN",
};

export const multiSalesOrgBillingCountries = ["UKR"];

export default function AddHardware({ props }) {
  const { isSourceCSP, cspDetails, notification, workTicketInfo } = useContext(C4SContext);
  const [addHardwareModal, setAddHardwareModal] = useState(false);
  const [deleteHardwareModal, setDeleteHardwareModal] = useState(false);
  const [isSaveButtonsDisabled, setIsSaveButtonsDisabled] = useState(true);
  const [hardwareMMIDTableList, setHardwareMMIDTableList] = useState([]);
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    timezone: "",
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const [installType, setInstallType] = useState({ value: "", error: false, errorText: "Enter valid year", success: false });
  const [deletedHardwareMMID, setDeletedHardwareMMID] = useState();
  const [editedHardwareMMID, setEditedHardwareMMID] = useState();
  const [isLoading, setLoader] = useState(false);
  const [editHardware, setEditHardware] = useState(false);
  const [hardwareDetails, setHardwareDetails] = useState([]);
  const [saveHardwareModal, setSaveHardwareModal] = useState(false);
  const [addAdditionAddress, setAddAdditionalAddress] = useState(false);
  const [salesOrg, setSalesOrg] = useState({ value: "", error: false, errorText: "Please validate the Sales Org.", success: false });
  const [contractItemList, setContractItemList] = useState([]);
  const [billingCountry, setBillingCountry] = useState("");
  const [salesOrgDropDown, setSalesOrgDropDown] = useState([]);
  const [division, setDivision] = useState({
    value: workTicketInfo.workTicketInfo.division,
    error: false,
    errorText: "Please validate the Division.",
    success: true,
  });
  const [distributionchannel, setDistributionchannel] = useState({
    value: workTicketInfo.workTicketInfo.distributionChannel,
    error: false,
    errorText: "Please validate the Distribution Channel.",
    success: true,
  });

  const [isEditFeatureEmpty, setIsEditFeatureEmpty] = useState(false);
  const [isInstallTypeSelected, setisInstallTypeSelected] = useState(false);
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    address.verified && hardwareMMIDTableList.length && installType.success && salesOrg.success && distributionchannel.success && division.success
      ? setIsSaveButtonsDisabled(false)
      : setIsSaveButtonsDisabled(true);
  }, [address.verified, installType.success, hardwareMMIDTableList.length, salesOrg.success, distributionchannel.success, division.success]);

  useEffect(() => {
    if (props && Object.keys(props).length) {
      let mmidTableList = props?.materials.map((records) => {
        let upsellUI = records.upsell == true ? "Yes" : records.upsell == false ? "No" : "--";
        let record = {
          id: generate(),
          mId: records.mid,
          materialId: records.materialId,
          orderQty: records.orderQty,
          chargeQty: records.chargeQty,
          chargeAmt: records.chargeAmt,
          currency: records.currency,
          description: records.itemDesc,
          contractItem: records.sapName,
          chargeAmtCurrency: `${records.chargeAmt}/${records.currency}`,
          type: records.type,
          displayType: getFullName(TYPE_DROPDOWN_VALUES, records.type),
          upsell: records.upsell,
          upsellForUI: upsellUI,
          techRefID: records.techRefId,
          feature: records.hardwareMaterialFeatures?.map((feature) => feature?.featureId),
          sapId: records.sapId,
          sapIdVal: records.sapIdVal,
          orderType: records.orderType,
          bundleId: records?.bundleId ? records.bundleId : "",
          hardwareMaterialFeatures: records.hardwareMaterialFeatures,
          featureIdList: records.hardwareMaterialFeatures,
        };
        return record;
      });
      setHardwareMMIDTableList(mmidTableList);
      setHardwareDetails(mmidTableList);
      handleInstallType(props.installType);
      handleFields("distributionchannel", props.distributionChannel);
      handleFields("division", props.division);
      setEditHardware(true);
    }
    if (props?.salesOrg) {
      let [salesOrgId, ...salesOrgName] = props.salesOrg.split(" ");
      let salesOrgValue = salesOrgName ? `(${salesOrgName.join(" ") || ""})` : "";
      salesOrgValue = `${salesOrgId || ""} ${salesOrgValue}`;

      setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
    } else {
      if (isSourceCSP.isSourceCSP) {
        let salesOrgValue = cspDetails.cspDetails?.salesOrgName ? `(${cspDetails.cspDetails?.salesOrgName || ""})` : "";
        salesOrgValue = `${cspDetails.cspDetails?.salesOrgId || ""} ${salesOrgValue}`;
        setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
      } else {
        let billingCountry = cspDetails.cspDetails?.cspData?.data?.data?.BillingCountry;
        setBillingCountry(billingCountry);
        let salesOrgDropDown = salesOrgList[billingCountry]?.map((item) => item.salesOrgId.replace("SO_", "") + " " + "(" + item.salesOrgName + ")");
        setSalesOrgDropDown(salesOrgDropDown);
        let salesOrgValue = cspDetails.cspDetails?.cspData?.data?.data?.CASEDetails[0]?.salesOrgName
          ? `(${cspDetails.cspDetails?.cspData?.data?.data?.CASEDetails[0]?.salesOrgName || ""})`
          : "";
        salesOrgValue = `${cspDetails.cspDetails?.cspData?.data?.data?.CASEDetails[0]?.salesOrg || ""} ${salesOrgValue}`;
        setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
      }
    }
  }, []);

  const handleHardware = (action, data) => {
    if (editHardware) {
      let hardwareMMID = hardwareDetails?.find((record) => record?.id === data?.id);
      if (hardwareMMID && action === "edited") {
        let existingFeatureIdList = new Map();
        let newFeatureIdList = new Map();
        hardwareMMID.hardwareMaterialFeatures.map((item) => {
          existingFeatureIdList.set(item.featureId, item);
        });
        data.hardwareMaterialFeatures.map((item) => {
          newFeatureIdList.set(item.featureId, item);
        });
        let newList = [];
        Array.from(existingFeatureIdList.keys()).map((fId) => {
          let updatedFeatureItem = existingFeatureIdList.get(fId);
          if (newFeatureIdList.has(fId)) {
            updatedFeatureItem.id
              ? newList.push({ ...updatedFeatureItem, actionType: "edited" })
              : newList.push({ ...updatedFeatureItem, actionType: "added" });
          } else {
            updatedFeatureItem.id && newList.push({ ...updatedFeatureItem, actionType: "deleted" });
          }
        });
        Array.from(newFeatureIdList.keys()).map((fId) => {
          let updatedFeatureItem = newFeatureIdList.get(fId);
          if (!existingFeatureIdList.has(fId)) {
            newList.push({ ...updatedFeatureItem, actionType: "added" });
          }
        });
        data.hardwareMaterialFeatures = newList;
        let updatedHardware = hardwareDetails.filter((item) => item.id !== data.id);
        setHardwareDetails([...updatedHardware, { ...data, actionType: action }]);
      } else if (hardwareMMID && action === "deleted") {
        let updatedHardware = hardwareDetails.filter((item) => item.id !== data.id);
        if (data?.featureIdList?.length) {
          let deletedHardwareMaterialFeatures = data.hardwareMaterialFeatures
            .filter((item) => item.id)
            .map((feature) => ({ ...feature, actionType: action }));
          let deletedMaterial = { ...data, hardwareMaterialFeatures: deletedHardwareMaterialFeatures };
          setHardwareDetails([...updatedHardware, deletedMaterial]);
        } else {
          setHardwareDetails([...updatedHardware]);
        }
      } else {
        let newHardwareMaterialFeatures = data?.hardwareMaterialFeatures.map((item) => {
          return { ...item, actionType: action };
        });
        data.hardwareMaterialFeatures = newHardwareMaterialFeatures;
        setHardwareDetails([...hardwareDetails, data]);
      }
    } else {
      if (action === "deleted") {
        let updatedhardware = hardwareDetails.filter((item) => item.id !== data.id);
        setHardwareDetails([...updatedhardware]);
      } else {
        let hardwareMMID = hardwareDetails?.find((record) => record?.id === data?.id);
        let newHardwareMaterialFeatures = data?.hardwareMaterialFeatures.map((item) => {
          return { ...item, actionType: "added" };
        });
        data.hardwareMaterialFeatures = newHardwareMaterialFeatures;
        if (hardwareMMID) {
          let updatedhardware = hardwareDetails.filter((item) => item.id !== data.id);
          setHardwareDetails([...updatedhardware, data]);
        } else {
          setHardwareDetails([...hardwareDetails, data]);
        }
      }
    }
  };

  const handleSaveHardware = async (addAdditionalAddress = false) => {
    // save logic goes here

    setLoader(true);
    let cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
    let hardwareMMID = hardwareDetails;
    let hardwareMMIDList = hardwareMMID.map((recordList) => {
      let hardwareMaterialFeaturesList = recordList.hardwareMaterialFeatures.map((item) => {
        item.actionType = item?.actionType ? item.actionType : "edited";
        return item;
      });

      let hardwareMaterialFeaturesListData = hardwareMaterialFeaturesList;

      if (isEditFeatureEmpty) {
        hardwareMaterialFeaturesListData = hardwareMaterialFeaturesList.map((data) => {
          return {
            actionType: "deleted",
            featureId: data.featureId,
            vmfId: data.vmfId ? data.vmfId : data.id,
          };
        });

        hardwareMaterialFeaturesListData = hardwareMaterialFeaturesListData.filter((data) => data.vmfId !== "");
      }

      return {
        id: recordList.recordType === "added" ? "" : recordList.id,
        mId: recordList.mId,
        materialId: recordList.materialId,
        orderQty: recordList.orderQty,
        chargeQty: recordList.chargeQty,
        chargeAmt: recordList.chargeAmt,
        currency: recordList.currency,
        hardwareMaterialFeatures: hardwareMaterialFeaturesListData,
        type: recordList.type,
        upsell: recordList.upsell,
        techRefId: recordList.techRefID,
        contractSapId: recordList.sapIdVal,
        sapIdVal: recordList.sapId,
        recordType: recordList.recordType,
        orderType: recordList.orderType,
        bundleId: isSourceCSP.isSourceCSP && recordList?.bundleId ? recordList?.bundleId : "",
      };
    });

    let data = {
      id: editHardware ? props.id : "",
      accountId: cspDetails.cspDetails.accountId,
      caseNumber: cspAccDetails?.CASEDetails[0]?.CaseNumber || "",
      address1: address.address1.value,
      address2: address.address2.value ?? null,
      city: address.city.value,
      country: address.country.value.code,
      stateCode: address.state.value.erpStateCode,
      timezone: address.timezone,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      zipCode: address.zip.value,
      caseId: isSourceCSP.isSourceCSP ? "" : cspDetails.cspDetails.caseId,
      cspId: isSourceCSP.isSourceCSP ? cspDetails.cspDetails.cspId : "",
      customerName: cspDetails.cspDetails.customerName,
      customerNumber: cspDetails.cspDetails.customerNumber,
      installType: installType.value,
      vztSegment: cspDetails.cspDetails.vztSegment,
      createdBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      modifiedBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      contractAccountId: workTicketInfo.workTicketInfo.contractAccount,
      distributionChannel: distributionchannel.value,
      division: division.value,
      hardwareDetails: hardwareMMIDList,
      salesOrgId: salesOrg.value.split(" ")[0],
    };
    let response = editHardware ? await updateHardware(data, isSourceCSP.isSourceCSP) : await saveHardware(data, isSourceCSP.isSourceCSP);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      notification.setNotification(<SLNotification key={generate()} title="Hardware added successfully" type="success" ref={clickRef} />);
      setTimeout(() => {
        notification.setNotification(null);
        if (!addAdditionalAddress) {
          redirectToPrevPage();
        }
        setLoader(false);
        clearFields();
      }, 3000);
    } else {
      notification.setNotification(
        <SLNotification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} ref={clickRef} />
      );
      setLoader(false);
    }
  };

  const handleSave = () => {
    if (isSourceCSP.isSourceCSP) {
      handleSaveHardware();
    } else {
      setSaveHardwareModal(true);
    }
  };

  const handleAddAdditionalAddress = () => {
    if (isSourceCSP.isSourceCSP) {
      handleSaveHardware(true);
      // clearFields();
    } else {
      setAddAdditionalAddress(true);
      setSaveHardwareModal(true);
    }
  };

  const clearFields = () => {
    setInstallType({ value: "", error: false, errorText: "Select install type", success: false });
    setAddress((prevState) => ({
      verified: false,
      addressByPass: null,
      locationFound: null,
      timezone: "",
      historicalAddress: { value: "", error: false, errorText: "", success: false },
      address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
      address2: { value: "", error: false, errorText: "Enter address 2", success: false },
      city: { value: "", error: false, errorText: "Enter city", success: false },
      state: { ...prevState.state, value: null, options: [], error: false, errorText: "Please Select State", success: false },
      zip: { value: "", error: false, errorText: "Enter zip code", success: false },
      country: { ...prevState.country, value: null, error: false, errorText: "Please select country", success: false },
    }));
    setHardwareMMIDTableList([]);
    setEditedHardwareMMID();
    setDeleteHardwareModal();
    setAddAdditionalAddress(false);
  };

  const showActionIcon = (row) => (
    <FlexRow>
      <div onClick={() => showEditHardwareModal(row)}>
        <img
          style={{ width: "15px", cursor: "pointer", marginRight: "20px" }}
          alt="edit-icon"
          aria-label="Edit Icon"
          src={edit}
          aria-controls="overlay_panel"
        />
      </div>
      <div onClick={() => showHardwareDeleteModal(row)}>
        <Icon
          className="trash-icon"
          aria-label="delete"
          alt="delete-icon"
          name="trash"
          size="medium"
          color="#000000"
          style={{ cursor: "pointer", outline: "none" }}
        />
      </div>
    </FlexRow>
  );

  const handleInstallType = (value) => {
    if (value && value.length) {
      setInstallType({ value, error: false, errorText: "Select install type", success: true });
      setisInstallTypeSelected(true);
    } else {
      setInstallType({ value, error: true, errorText: "Select install type", success: false });
    }
  };

  const handleFields = (field, value) => {
    switch (field) {
      case "division": {
        value && value.length
          ? setDivision({ value, error: false, errorText: "Select division", success: true })
          : setDivision({ value: "", error: true, errorText: "Select division", success: false });
        break;
      }
      case "distributionchannel": {
        value && value.length
          ? setDistributionchannel({ value, error: false, errorText: "Select distribution channel", success: true })
          : setDistributionchannel({ value: "", error: true, errorText: "Select distribution channel", success: false });
        break;
      }
    }
  };

  const showEditHardwareModal = (row) => {
    setAddHardwareModal(true);
    setEditedHardwareMMID(row);
  };

  const showHardwareDeleteModal = (row) => {
    setDeleteHardwareModal(true);
    setDeletedHardwareMMID(row);
  };

  const handleHardwareMMIDRecordDelete = (rowData) => {
    let tableData = _.cloneDeep(hardwareMMIDTableList).filter((item) => item.id !== rowData.id);
    setHardwareMMIDTableList(tableData);
  };

  const handleSalesOrg = (e) => {
    setSalesOrg((prevState) => ({ ...prevState, value: e.target.value, error: false, success: true }));
  };

  let installTypeList;
  if (!isInstallTypeSelected) {
    installTypeList = ["Please select", "CMI", "VMI"];
  } else {
    installTypeList = ["CMI", "VMI"];
  }

  return (
    <>
      <Loader active={isLoading} fullscreen />
      {addHardwareModal && (
        <AddHardwareModal
          addHardwareModal={addHardwareModal}
          setAddHardwareModal={setAddHardwareModal}
          hardwareMMIDTableList={hardwareMMIDTableList}
          setHardwareMMIDTableList={setHardwareMMIDTableList}
          editedHardwareMMID={editedHardwareMMID}
          handleHardware={handleHardware}
          contractItemList={contractItemList}
          setContractItemList={setContractItemList}
          salesOrgId={salesOrg.value?.split("(")[0].trim()}
          currency={salesOrgBasedCurrency["SO_" + salesOrg.value?.split("(")[0].trim()]}
          setIsEditFeatureEmpty={setIsEditFeatureEmpty}
        />
      )}
      {deleteHardwareModal && (
        <DeleteHardwareModal
          deleteHardwareModal={deleteHardwareModal}
          setDeleteHardwareModal={setDeleteHardwareModal}
          deletedHardwareMMID={deletedHardwareMMID}
          handleHardwareMMIDRecordDelete={handleHardwareMMIDRecordDelete}
          handleHardware={handleHardware}
        />
      )}
      {!isSourceCSP.isSourceCSP && saveHardwareModal && (
        <SaveHardwareModal
          hardwareMMIDTableList={hardwareMMIDTableList}
          saveHardwareModal={saveHardwareModal}
          setSaveHardwareModal={setSaveHardwareModal}
          addAdditionAddress={addAdditionAddress}
          setAddAdditionalAddress={setAddAdditionalAddress}
          handleSaveHardware={handleSaveHardware}
          currency={salesOrgBasedCurrency["SO_" + salesOrg.value?.split("(")[0].trim()]}
        />
      )}
      <Header>Add Hardware</Header>
      <Spacer bottom="10px" />
      <ValidateAddress address={address} setAddress={setAddress} vehicleData={props} showHistoricalAddressDropdown={!props} setLoader={setLoader} />
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "1.25rem", paddingLeft: "10px !important" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <SLGridLayout>
              <SLRowLayout css={{ marginTop: "1.25rem", paddingLeft: "10px !important"}}>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 4 }}>
                  {!isSourceCSP.isSourceCSP ? (
                    multiSalesOrgBillingCountries.includes(billingCountry) ? (
                      <div>
                        <DropdownSelect
                          label="Sales Org"
                          errorText={salesOrg.errorText}
                          error={salesOrg.error}
                          value={salesOrg.value ? salesOrg.value : ""}
                          inlineLabel={false}
                          onChange={(event) => handleSalesOrg(event)}
                          css={{ marginBottom: "0.6rem", marginRight: "5px" }}
                        >
                          {salesOrgDropDown.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </DropdownSelect>
                      </div>
                    ) : (
                      <CustomInput
                        type="text"
                        label="Sales Org"
                        value={salesOrg.value ? salesOrg.value : ""}
                        errorText={salesOrg.errorText}
                        error={salesOrg.error}
                        success={salesOrg.success}
                        disabled
                        paddingRight="5px"
                      />
                    )
                  ) : (
                    <CustomInput
                      type="text"
                      label="Sales Org"
                      value={salesOrg.value ? salesOrg.value : ""}
                      errorText={salesOrg.errorText}
                      error={salesOrg.error}
                      success={salesOrg.success}
                      disabled
                      paddingRight="5px"
                    />
                  )}
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 4 }}>
                  <CustomDropdown
                    label="Division"
                    value={division.value ? division.value : "Please select"}
                    errorText={division.errorText}
                    error={division.error}
                    success={division.success}
                    onChange={(e) => handleFields("division", e.target.value)}
                    marginBottom="10px"
                    paddingRight="5px"
                  >
                    <option disabled>Please select</option>
                    {DIVISION_DROPDOWN_VALUES.map((record) => (
                      <option key={record} value={record.code}>
                        {`${record.code} - ${record.description}`}
                      </option>
                    ))}
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 4 }}>
                  <CustomDropdown
                    label="Distribution Channel"
                    value={distributionchannel.value ? distributionchannel.value : "Please select"}
                    errorText={distributionchannel.errorText}
                    error={distributionchannel.error}
                    success={distributionchannel.success}
                    onChange={(e) => handleFields("distributionchannel", e.target.value)}
                    marginBottom="10px"
                    paddingRight="5px"
                  >
                    <option disabled>Please select</option>
                    {DISTRIBUTION_CHANNEL_DROPDOWN_VALUES.map((record) => (
                      <option key={record} value={record.code}>
                        {`${record.code} - ${record.description}`}
                      </option>
                    ))}
                  </CustomDropdown>
                </CompactCol>
              </SLRowLayout>
            </SLGridLayout>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>

      <Spacer bottom="10px" />
      <>
        <UltraMediumText tabIndex={0} css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="1rem">
          Install Type
        </UltraMediumText>
        <CustomDropdown
          width={!isSourceCSP.isSourceCSP ? "35%" : "280px"}
          label="Install Type"
          value={installType.value}
          error={installType.error}
          errorText={installType.errorText}
          success={installType.success}
          onChange={(e) => handleInstallType(e.target.value)}
        >
          {installTypeList.map((option, i) => (
            <option key={i}>{option}</option>
          ))}
        </CustomDropdown>
      </>
      <UltraMediumText tabIndex={0} css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0rem">
        Hardware
      </UltraMediumText>
      <div tabIndex={0} aria-label="table">
        <GridComponent
          isExportEnabled={false}
          noDataMessage="No data available to display"
          rows={hardwareMMIDTableList}
          showActionIcon={showActionIcon}
          columns={isSourceCSP.isSourceCSP ? ADD_HARDWARE_CSP_COL : ADD_HARDWARE_CASE_COL}
          hideGlobalFilter={true}
          whiteSpace="normal"
          paginator={false}
        />
      </div>
      <Spacer top="10px" />
      <CustomSLButton
        secondary
        aria-label="Add Hardware"
        onClick={() => {
          setAddHardwareModal(true);
          setEditedHardwareMMID(null);
        }}
      >
        Add Hardware
      </CustomSLButton>
      <Spacer top="10px" />
      <Spacer top="2rem" />
      <FlexRow>
        <CustomSLButton primary aria-label="Save" onClick={handleSave} disabled={isSaveButtonsDisabled}>
          Save
        </CustomSLButton>
        <CustomSLButton aria-label="Add Additional Address" secondary onClick={handleAddAdditionalAddress} disabled={isSaveButtonsDisabled}>
          Add Additional Address
        </CustomSLButton>
        <CustomSLButton
          secondary
          onClick={() => {
            redirectToPrevPage();
          }}
        >
          Cancel
        </CustomSLButton>
      </FlexRow>
    </>
  );
}

export const AddHardwareMetadata = {
  name: "Add Hardware",
  id: "add_hardware",
  component: lazy(() => import("./AddHardware")),
  route: "/add_hardware",
};
