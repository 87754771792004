import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexColumn, FlexRow, Spacer, SmallText, SLNotification } from "../../components/core/utility";
import Icon from "@vds/icons";
import { CustomDropdown, CustomInput } from "./AddAVehicle";
import { C4SContext } from "../../context/c4sContext";
import { generate } from "shortid";
import _ from "lodash";
import { getFeatureList } from "../../services/C4S Decommission - Replacement/getFeatureList";

import { Loader } from "@vds/loaders";
import { getFullName, TYPE_DROPDOWN_VALUES } from "./utils";
import searchIcon from "../../assets/icons/search_blk.png";
import { PHONE_REGEX } from "../../constants/C4SValidationConstants";
import { materialIdSearch } from "../../services/C4S Decommission - Replacement/mmidSearch";
import { getSapIdList } from "../../services/C4S Decommission - Replacement/getSapIdList";
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { DialogModal } from "./AddVehicleMMIDModal";
import useOutsideClick from "../../customHook/use-outside-click";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const CustomisedInput = styled(MultiSelect)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  font-family: Verizon-NHG-eTX;
  width: 100%;
  box-shadow: none !important;
  border-radius: 0;
  .dropdown-content {
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: absolute;
    z-index: 100000;
    border: unset !important;
  }
  .dropdown-container {
    border: unset !important;
  }
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 8px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
`;

export const CustomNotification = styled(SLNotification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 20 1 0%;
    }
  }
`;

export const CustomisedNotification = css`
  & span:last-child {
    max-width: fit-content;
  }
`;

export const AddHardwareModal = ({
  addHardwareModal,
  setAddHardwareModal,
  hardwareMMIDTableList,
  setHardwareMMIDTableList,
  editedHardwareMMID,
  handleHardware,
  contractItemList,
  setContractItemList,
  salesOrgId,
  currency,
  setIsEditFeatureEmpty,
}) => {
  const { isSourceCSP } = useContext(C4SContext);
  const [materialId, setMaterialId] = useState({ value: "", error: false, errorText: "Enter valid MMID", success: false });
  const [description, setDescription] = useState({ value: "", error: false, errorText: "Enter valid Item Description", success: false });
  const [contractItem, setContractItem] = useState({ value: "", error: false, errorText: "Select Contract Item", success: false });
  const [quantity, setQuantity] = useState({ value: "", error: false, errorText: "Enter valid Quantity", success: false });
  const [chargedAmount, setChargedAmount] = useState({ value: "", error: false, errorText: "Enter valid Charged Amount", success: false });
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);
  const [orderType, setOrderType] = useState({ value: "", error: false, errorText: "Enter valid order type", success: false });
  const [upsell, setUpsell] = useState({ value: "", error: false, errorText: "Enter valid Upsell", success: false });
  const [type, setType] = useState({ value: "", error: false, errorText: "Enter valid Type", success: false });
  const [techRefID, setTechRefID] = useState({ value: "", error: false, errorText: "Enter valid Tech Ref Id", success: false });
  const [feature, setFeature] = useState({ value: [], error: false, errorText: "", success: false });
  const [isLoading, setLoader] = useState(false);
  const [isUpsell, setIsUpsell] = useState(false);
  const [action, setAction] = useState("Add Hardware");
  const [notification, setNotification] = useState(null);
  const [warning, setWarning] = useState(null);
  const [featureValues, setfeatureValues] = useState([]);
  const { cspDetails, techRefIdList, isGovernmentAcc } = useContext(C4SContext);
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [isMMIDValidated, setIsMMIDValidated] = useState(false);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const hardwareFlowSapIds = ["1400000043", "1400000018", "1400000004", "1400000041"];
  const [sapIdVal, setSapIdVal] = useState("");
  const [contractSapId, setContractSapId] = useState("");
  const [mId, setMId] = useState(editedHardwareMMID?.mId);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isCaseSearchDisabled, setIsCaseSearchDisabled] = useState(true);
  const [filteredTechRefIdList, setFilteredTechRefIdList] = useState([]);
  const [hardwareMaterialFeaturesList, setHardwareMaterialFeaturesList] = useState([]);
  let featureList = [];

  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setAddHardwareModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    let productList = isSourceCSP?.isSourceCSP ? [...new Set(cspDetails?.cspDetails?.product?.map((i) => i.OrderDetailType))] : [];
    setOrderTypeOptions(productList);
  }, [cspDetails]);

  useEffect(() => {
    if (materialId.success && contractItem.success && orderType.success) {
      setIsSearchDisabled(false);
    } else {
      if (materialId.success && upsell.value === "Yes" && orderType.success) {
        setIsSearchDisabled(false);
      } else {
        setIsSearchDisabled(true);
      }
    }
    if (materialId.success) {
      setIsCaseSearchDisabled(false);
    } else {
      setIsCaseSearchDisabled(true);
    }
  }, [materialId.success, contractItem.success, orderType.success, upsell.value]);

  useEffect(() => {
    !isMMIDValidated && setIsFieldDisabled(true);
    if (isSourceCSP.isSourceCSP) {
      if (upsell.value === "No") {
        materialId.success &&
        quantity.success &&
        upsell.success &&
        description.success &&
        type.success &&
        feature.success &&
        contractItem.success &&
        techRefID.success &&
        isMMIDValidated
          ? setIsAddBtnDisabled(false)
          : setIsAddBtnDisabled(true);
      } else {
        materialId.success && quantity.success && upsell.success && description.success && type.success && feature.success && isMMIDValidated
          ? setIsAddBtnDisabled(false)
          : setIsAddBtnDisabled(true);
      }
    } else {
      materialId.success && quantity.success && type.success && description.value && feature.success && isMMIDValidated && !chargedAmount.error
        ? setIsAddBtnDisabled(false)
        : setIsAddBtnDisabled(true);
    }
  }, [
    materialId.success,
    description.success,
    contractItem.success,
    quantity.success,
    chargedAmount.error,
    upsell.value,
    upsell.success,
    type.success,
    contractItem.success,
    techRefID.success,
    feature.success,
    isMMIDValidated,
  ]);

  useEffect(() => {
    !(featureValues && featureValues?.length > 0) && fetchFeatureList();
    if (isSourceCSP.isSourceCSP) {
      onContractItemClick();
    }
    if (editedHardwareMMID && Object.keys(editedHardwareMMID).length) {
      setAction("Edit Hardware");
      handleFields("MMID", editedHardwareMMID.materialId);
      handleFields("description", editedHardwareMMID.description);
      handleFields("contractItem", editedHardwareMMID.contractItem);
      handleFields("quantity", editedHardwareMMID.orderQty);
      handleFields("chargedAmount", editedHardwareMMID.chargeAmt);
      handleFields("currency", editedHardwareMMID.currency);
      handleFields("upsell", editedHardwareMMID.upsell);
      handleFields("type", editedHardwareMMID.type);
      // handleFields("techRefID", editedHardwareMMID.techRefID);
      // let featArray = [];
      // editedHardwareMMID.feature.forEach(function (num) {
      //   featArray.push(String(num));
      // });
      //handleFields("feature", itemEditdata);
      handleFields("orderType", editedHardwareMMID.orderType);
      setHardwareMaterialFeaturesList(editedHardwareMMID?.hardwareMaterialFeatures);
      if (isSourceCSP.isSourceCSP) {
        if (editedHardwareMMID.orderType && editedHardwareMMID.contractItem && editedHardwareMMID.materialId) {
          handleTechRefId(editedHardwareMMID?.orderType, editedHardwareMMID?.sapIdVal);
          setIsFieldDisabled(false);
          setIsMMIDValidated(true);
        } else {
          handleFields("techRefID", editedHardwareMMID?.techRefID);
        }
      } else {
        if (editedHardwareMMID.type && editedHardwareMMID.materialId) {
          setIsFieldDisabled(false);
          setIsMMIDValidated(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (editedHardwareMMID && Object.keys(editedHardwareMMID).length) {
      let mmidTableListExisting = editedHardwareMMID?.feature?.map((records) => records);
      let featureListToEdit = featureValues?.filter((item) => mmidTableListExisting?.includes(item.id));

      let featOptionList = featureListToEdit?.map((data) => {
        return { label: data.feature, value: data.id };
      });

      handleFields("feature", featOptionList);
    }
  }, [editedHardwareMMID, featureValues]);

  const handleFields = (field, value) => {
    switch (field) {
      case "MMID": {
        setIsMMIDValidated(false);
        if (value && value.length) {
          if (PHONE_REGEX?.test(value)) {
            setMaterialId({ value, error: false, errorText: "Enter valid MMID", success: true });
          } else {
            setMaterialId({ value, error: true, errorText: "Enter valid MMID", success: false });
          }
        } else {
          setMaterialId({ value: "", error: true, errorText: "Enter MMID", success: false });
        }
        break;
      }
      case "description": {
        if (value && value.length) {
          setDescription({ value, error: false, errorText: "", success: true });
        } else {
          setDescription({ value, error: true, errorText: "Enter valid Item Description", success: false });
        }
        break;
      }
      case "contractItem": {
        setIsMMIDValidated(false);
        if (value && value.length) {
          setContractItem({ value, error: false, errorText: "", success: true });
          contractItemList.map((item) => {
            if (item.contractName === value) {
              setSapIdVal(item.contractId);
              setContractSapId(item.sapId);
            }
          });
        } else {
          setContractItem({ value, error: true, errorText: "Select Contract Item", success: false });
        }
        break;
      }
      case "quantity": {
        if (value && value >= 0) {
          setQuantity({ value, error: false, errorText: "", success: true });
        } else {
          setQuantity({ value, error: true, errorText: "Enter valid quantity", success: false });
        }
        break;
      }
      case "feature": {
        if (value && value.length) {
          value.map((val) => {
            featureList.push(val);
          });
          let lineItemFeaturesList = featureList.map((item) => {
            return { id: "", featureId: item.value };
          });
          setHardwareMaterialFeaturesList(lineItemFeaturesList);
          setFeature({ value: featureList, error: false, errorText: "", success: true });
        } else {
          setFeature({ value: featureList, error: true, errorText: "Select feature", success: false });
        }

        if (value.length === 0) {
          setIsEditFeatureEmpty(true);
        } else {
          setIsEditFeatureEmpty(false);
        }
        break;
      }
      case "chargedAmount": {
        if (isSourceCSP.isSourceCSP) {
          if (value) {
            setChargedAmount({ value, error: false, errorText: "", success: true });
          } else {
            setChargedAmount({ value, error: true, errorText: "Enter valid charged amount", success: false });
          }
        } else {
          // setChargedAmount({ value, error: false, errorText: "", success: true });
          const chargedAmountRegex = /^([0-9]\d*)(\.\d+)?$/;
          if (value && value?.length) {
            if (chargedAmountRegex.test(value)) {
              setChargedAmount({ value: value, error: false, errorText: "", success: true });
            } else {
              setChargedAmount({ value, error: true, errorText: "Enter valid charged amount", success: false });
            }
          } else {
            setChargedAmount({ value, error: false, errorText: "", success: true });
          }
        }
        break;
      }
      case "upsell": {
        if (value != null || value != "") {
          if (value === "Yes" || value === true) {
            setUpsell({ value: "Yes", error: false, errorText: "", success: true });
            setIsUpsell(true);
          } else {
            setUpsell({ value: "No", error: false, errorText: "", success: true });
            setIsUpsell(false);
          }
        } else {
          setUpsell({ value, error: true, errorText: "Enter valid Upsell", success: false });
        }
        break;
      }
      case "type": {
        if (value) {
          setType({ value, error: false, errorText: "", success: true });
        } else {
          setType({ value, error: true, errorText: "Enter valid Type", success: false });
        }
        break;
      }
      case "orderType": {
        setIsMMIDValidated(false);
        if (value && value.length) {
          setOrderType({ value, error: false, errorText: "", success: true });
          if (value?.toLowerCase() !== "upsell") {
            setUpsell({ value: "No", error: false, errorText: "", success: true });
            setIsUpsell(false);
          } else {
            if (editedHardwareMMID && Object.keys(editedHardwareMMID).length) {
              handleFields("upsell", editedHardwareMMID.upsell);
            } else {
              setUpsell({ value: "", error: false, errorText: "Enter valid upsell", success: false });
            }
          }
        } else {
          setOrderType({ value, error: true, errorText: "Please select order type", success: false });
        }
        break;
      }
      case "techRefID": {
        if (value && value.length) {
          setTechRefID({ value, error: false, errorText: "", success: true });
        } else {
          setTechRefID({ value, error: true, errorText: "Please select Tech Ref Id", success: false });
        }
        break;
      }
    }
  };

  const handleAddHardwareMMID = () => {
    let bundleID = cspDetails?.cspDetails?.product?.filter((record) => record.TechRefId === techRefID.value)[0]?.BundleId;
    let mmidRecord = {
      id: editedHardwareMMID?.id ? editedHardwareMMID?.id : generate(),
      mId: mId,
      materialId: materialId.value,
      orderQty: quantity.value,
      chargeQty: "",
      chargeAmt: chargedAmount.value == "" ? 0 : Number(chargedAmount.value) ? Number(chargedAmount.value) : 0,
      currency: currency,
      description: description.value,
      contractItem: contractItem.value,
      sapIdVal: contractSapId ? contractSapId : editedHardwareMMID ? editedHardwareMMID.sapIdVal : null,
      sapId: sapIdVal ? sapIdVal : editedHardwareMMID?.sapId,
      chargeAmtCurrency: `${chargedAmount.value == "" ? 0 : chargedAmount.value}/${currency}`,
      upsell: upsell.value === "Yes" ? true : false,
      type: type.value ? type.value : null,
      displayType: getFullName(TYPE_DROPDOWN_VALUES, type.value),
      upsellForUI: upsell?.value,
      orderType: orderType.value ? orderType.value : null,
      techRefID: upsell.value === "Yes" ? "" : techRefID.value,
      feature: feature?.value?.map((id) => id.value),
      hardwareMaterialFeatures: hardwareMaterialFeaturesList?.length ? hardwareMaterialFeaturesList : editedHardwareMMID.hardwareMaterialFeatures,
      featureIdList: editedHardwareMMID?.featureIdList ? editedHardwareMMID?.featureIdList : [],
      bundleId: bundleID || "",
    };
    let newHardwareMMIDList = _.cloneDeep(hardwareMMIDTableList).filter((record) => record.id !== mmidRecord.id);
    setHardwareMMIDTableList([mmidRecord, ...newHardwareMMIDList]);
    if (editedHardwareMMID) {
      if (!_.isEqual(editedHardwareMMID, mmidRecord)) {
        handleHardware("edited", mmidRecord);
      }
    } else {
      handleHardware("added", mmidRecord);
    }

    setAddHardwareModal(false);
  };

  const fetchFeatureList = async () => {
    setLoader(true);
    let featType = isSourceCSP.isSourceCSP ? "HARDWARE" : "VIN";
    let response = await getFeatureList(featType);
    if (response?.data?.statusCode === "200" && response?.data) {
      let data = response.data.data;
      const sortedFeatures = data.sort((a, b) => (a.feature > b.feature ? 1 : -1));
      setfeatureValues(sortedFeatures);
      setLoader(false);
    } else {
      setLoader(false);
      notify("Error occurred while fetching Feature List. Please try again", "error", false);
    }
  };

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(
      <CustomNotification
        key={generate()}
        type={type}
        title={content}
        fullBleed={true}
        onCloseButtonClick={() => setNotification(null)}
        ref={clickRef}
      />
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const handleMMIDSearch = async () => {
    // if (materialId.success && (isSourceCSP.isSourceCSP ? contractItem.success : true) && (isSourceCSP.isSourceCSP ? orderType.success : true)) {
    if (materialId.success && (isSourceCSP.isSourceCSP ? !isSearchDisabled : true)) {
      setLoader(true);
      let data;
      if (upsell.value === "Yes" && orderType.value === "Upsell") {
        data = {
          orderType: orderType.value,
          salesOrgId: salesOrgId,
          sapId: "",
          mmId: materialId.value,
          governmentAcc: isGovernmentAcc.isGovernmentAcc,
          type: "",
          contractOverride: true,
          processFlow: "Shipment",
        };
      } else {
        data = {
          orderType: orderType.value,
          salesOrgId: salesOrgId,
          sapId: contractSapId,
          mmId: materialId.value,
          governmentAcc: isGovernmentAcc.isGovernmentAcc,
          type: "",
          contractOverride: false,
          processFlow: "Shipment",
        };
      }

      let response = await materialIdSearch(data, isSourceCSP.isSourceCSP);
      if (response?.status === 200) {
        response = response?.data?.data;
        if (response.mmidValid) {
          if (response.materialContract.length) {
            handleFields("description", response.materialName);
            setIsFieldDisabled(false);
            setIsMMIDValidated(true);
            setMId(response.id);
            setType({ value: response.defaultType, error: false, errorText: "", success: true });
          } else {
            notify("Invalid MMID", "error", false);
          }
          handleTechRefId(orderType.value, contractSapId);
        } else {
          notify("Invalid MMID", "error", false);
        }
      } else {
        notify("We are currently experiencing issues, please try again", "error", false);
      }
      setLoader(false);
    }
  };

  const handleTechRefId = (orderType, contractItem) => {
    let productArray = cspDetails?.cspDetails?.product;
    let techRefList = [];
    if (productArray?.length > 0) {
      productArray.map((item) => {
        item?.OrderDetailType?.toLowerCase() === orderType?.toLowerCase() && item?.SAPProductID === contractItem && techRefList.push(item?.TechRefId);
      });
      techRefList.includes(techRefID.value) ? handleFields("techRefID", techRefID.value) : techRefID.value && handleFields("techRefID", "");
    }
    if (editedHardwareMMID && Object.keys(editedHardwareMMID).length) {
      let techRefVal = techRefID.value ? techRefID.value : editedHardwareMMID?.techRefID;
      techRefList.includes(techRefVal) ? handleFields("techRefID", techRefVal) : handleFields("techRefID", "");
    }
    setFilteredTechRefIdList(techRefList);
  };

  const onContractItemClick = async () => {
    setLoader(true);
    let response = await getSapIdList(cspDetails?.cspDetails?.salesOrgId);
    if (response?.status === 200) {
      response = response?.data?.data;
      if (response.length > 0) {
        if (isSourceCSP.isSourceCSP) {
          response = response.filter((item) => hardwareFlowSapIds.includes(item.sapId?.toString()));
          !(response && Object.keys(response).length) &&
            setNotification(
              <>
                <CustomNotification key={generate()} type={"error"} title={"There is no matching Contract Item for the sales org"} fullBleed={true} />
              </>
            );
        }

        setContractItemList(response);
        if (editedHardwareMMID && Object.keys(editedHardwareMMID).length) {
          response.map((item) => {
            if (item.contractName === editedHardwareMMID.contractItem) {
              setSapIdVal(item.contractId);
              setContractSapId(item.sapId);
            }
          });
        }
      } else {
        notify("There is no Contract Item for the sales org", "error", false);
      }
    } else {
      notify("We are currently experiencing issues, please try again", "error", false);
    }
    setLoader(false);
  };
  const onShow = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    selectAllCheckbox.after(" Select All");
  };
  useEffect(() => {
    if (!isSourceCSP.isSourceCSP) {
      setWarning(
        <SLNotification
          type="warning"
          title="If replacement requires a hardware charge, please update the Charged amount for the unit price."
          key={generate()}
          hideCloseButton={true}
          css={CustomisedNotification}
        />
      );
    }
  }, [isSourceCSP]);

  return (
    <>
      <Spacer top="10px" />
      <DialogModal
        visible={true}
        onHide={() => setAddHardwareModal(false)}
        focusOnShow={true}
        opened={addHardwareModal}
        ariaLabel="Add Hardware Modal"
        header={
          <FlexRow justifyContent="space-between" style={{ padding: "15px" }}>
            <div>
              <Title size="medium">Add Hardware</Title>
            </div>
          </FlexRow>
        }
        //style={{ width: "65vw", height: "100%", overflowY: "auto" }}
        style={{ width: "65vw", overflowY: "auto" }}
      >
        <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
          {!isSourceCSP.isSourceCSP && !notification && warning}
          {notification}
          <Spacer top="1rem" />
          <FlexRow>
            {isSourceCSP.isSourceCSP && (
              <CustomDropdown
                width="50%"
                type="text"
                label="Order Type"
                value={orderType.value ? orderType.value : "Please select"}
                error={orderType.error}
                errorText={orderType.errorText}
                success={orderType.success}
                onChange={(e) => handleFields("orderType", e.target.value)}
                paddingRight="5px"
              >
                <>
                  <option disabled>Please select</option>
                  {orderTypeOptions.map((type) => (
                    <option key={type}>{type}</option>
                  ))}
                </>
              </CustomDropdown>
            )}
            {orderType?.value?.toLowerCase() === "upsell" && (
              <CustomDropdown
                width="50%"
                type="text"
                label="Contract Override"
                value={upsell.value ? upsell.value : "Please select"}
                error=""
                errorText=""
                success=""
                onChange={(e) => handleFields("upsell", e.target.value)}
                paddingRight="5px"
                // disabled={isFieldDisabled}
              >
                <option disabled>Please select</option>
                <option>Yes</option>
                <option>No</option>
              </CustomDropdown>
            )}
            {isSourceCSP.isSourceCSP && !isUpsell && (
              <CustomDropdown
                width="50%"
                label="Contract Item"
                value={contractItem.value ? contractItem.value : "Please select"}
                error={contractItem.error}
                errorText={contractItem.errorText}
                success={contractItem.success}
                onChange={(e) => handleFields("contractItem", e.target.value)}
                // onClick={!contractItemList.length > 0 && onContractItemClick}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
                //disabled={isFieldDisabled}
              >
                <option disabled>Please select</option>
                {contractItemList.map((list) => (
                  <option key={list.contractId}>{list.contractName}</option>
                ))}
              </CustomDropdown>
            )}

            <CustomInput
              formWidth={!isSourceCSP.isSourceCSP ? "42%" : "50%"}
              type="text"
              label="MMID"
              value={materialId.value}
              error={materialId.error}
              errorText={materialId.errorText}
              success={materialId.success}
              onChange={(e) => handleFields("MMID", e.target.value)}
              paddingRight="5px"
              maxLength={10}
            />
            {isSourceCSP.isSourceCSP ? (
              <CustomSLButton
                css={orderType.value === "Upsell" && !isUpsell ? { margin: "10px 20px 10px 0px" } : { margin: "19px 20px 10px 0px" }}
                primary
                ariaLabel="search"
                disabled={isSearchDisabled}
                tabIndex={0}
                onClick={handleMMIDSearch}
              >
                Search
              </CustomSLButton>
            ) : (
              <CustomSLButton
                css={orderType.value === "Upsell" && !isUpsell ? { margin: "10px 20px 10px 0px" } : { margin: "19px 20px 10px 0px" }}
                primary
                ariaLabel="search"
                disabled={isCaseSearchDisabled}
                tabIndex={0}
                onClick={handleMMIDSearch}
              >
                Search
              </CustomSLButton>
            )}
          </FlexRow>
          <CustomInput
            width="100%"
            type="text"
            label="Item Description"
            value={description.value}
            error={description.error}
            errorText={description.errorText}
            success={description.success}
            onChange={(e) => handleFields("description", e.target.value)}
            paddingRight="5px"
            disabled={true}
          />
          {isSourceCSP.isSourceCSP && (
            <FlexRow>
              {!isUpsell && (
                <CustomDropdown
                  width="100%"
                  type="text"
                  label="Tech Ref ID"
                  value={techRefID.value ? techRefID.value : "Please select"}
                  error={techRefID.error}
                  errorText={techRefID.errorText}
                  success={techRefID.success}
                  onChange={(e) => handleFields("techRefID", e.target.value)}
                  paddingRight="5px"
                  css={{ paddingBottom: "0.6rem" }}
                  disabled={isFieldDisabled}
                >
                  <option disabled>Please select</option>
                  {filteredTechRefIdList.map((refId) => (
                    <option key={refId}>{refId}</option>
                  ))}
                </CustomDropdown>
              )}
              <Loader active={isLoading} />
              <div style={{ width: "99.5%", marginBottom: "10px", paddingRight: "0.5%" }}>
                <SmallText css={{ marginTop: "0px" }} disabled={isFieldDisabled}>
                  Feature
                </SmallText>
                <div tabIndex={0} aria-label="feature press Enter key to select from list">
                  <CustomisedInput
                    options={featureValues.map((data) => {
                      return { label: data.feature, value: data.id };
                    })}
                    value={feature?.value}
                    disabled={isFieldDisabled}
                    error={feature.error}
                    errorText={feature.errorText}
                    success={feature.success}
                    onChange={(selected) => handleFields("feature", selected)}
                    disableSearch={true}
                    labelledBy={"Select"}
                    isCreatable={false}
                  />
                </div>
              </div>
              <CustomInput
                formWidth="50%"
                type="number"
                label="Quantity"
                value={quantity.value}
                error={quantity.error}
                errorText={quantity.errorText}
                success={quantity.success}
                onChange={(e) => handleFields("quantity", e.target.value)}
                paddingRight="5px"
                disabled={isFieldDisabled}
              />
              {isSourceCSP.isSourceCSP && (
                <CustomDropdown
                  width="50%"
                  type="text"
                  label="Type"
                  value={type.value ? type.value : "Please select"}
                  error={type.error}
                  errorText={type.errorText}
                  success={type.success}
                  onChange={(e) => handleFields("type", e.target.value)}
                  disabled={isFieldDisabled}
                  paddingRight="5px"
                  css={{ paddingBottom: "0.6rem" }}
                >
                  <>
                    <option disabled>Please select</option>
                    {TYPE_DROPDOWN_VALUES.map((type) => (
                      <option key={type} value={type.code}>{`${type.code} - ${type.description}`}</option>
                    ))}
                  </>
                </CustomDropdown>
              )}
            </FlexRow>
          )}
          {!isSourceCSP.isSourceCSP && (
            <>
              <FlexRow>
                <Loader active={isLoading} />
                <div style={{ width: "99.5%", marginBottom: "10px", paddingRight: "0.5%" }}>
                  <SmallText css={{ marginTop: "0px" }} disabled={isFieldDisabled}>
                    Feature
                  </SmallText>

                  <div tabIndex={0} aria-label="feature press space bar to select from list">
                    <CustomisedInput
                      css={{ width: "100%" }}
                      label="Feature"
                      value={feature.value}
                      error={feature.error}
                      errorText={feature.errorText}
                      success={feature.success}
                      disableSearch={true}
                      // onShow={onShow}
                      disabled={isFieldDisabled}
                      options={featureValues.map((data) => {
                        return { label: data.feature, value: data.id };
                      })}
                      onChange={(selected) => handleFields("feature", selected)}
                    ></CustomisedInput>
                  </div>
                </div>

                <CustomInput
                  formWidth="100%"
                  type="number"
                  label="Ordered Quantity"
                  value={quantity.value}
                  error={quantity.error}
                  errorText={quantity.errorText}
                  success={quantity.success}
                  onChange={(e) => handleFields("quantity", e.target.value)}
                  paddingRight="5px"
                  disabled={isFieldDisabled}
                />
              </FlexRow>
              <FlexRow>
                <CustomInput
                  formWidth="50%"
                  type="text"
                  label="Charged Amount (per unit)"
                  value={chargedAmount.value}
                  error={chargedAmount.error}
                  errorText={chargedAmount.errorText}
                  success={chargedAmount.success}
                  onChange={(e) => handleFields("chargedAmount", e.target.value)}
                  paddingRight="5px"
                  disabled={isFieldDisabled}
                />
                <CustomInput formWidth="50%" type="text" label="Currency" value={currency} paddingRight="5px" disabled={true} />
                <CustomDropdown
                  width="50%"
                  type="text"
                  label="Type"
                  value={type.value ? type.value : "Please select"}
                  error={type.error}
                  errorText={type.errorText}
                  success={type.success}
                  onChange={(e) => handleFields("type", e.target.value)}
                  paddingRight="5px"
                >
                  <>
                    <option disabled>Please select</option>
                    {TYPE_DROPDOWN_VALUES.map((type) => (
                      <option key={type} value={type.code}>{`${type.code} - ${type.description}`}</option>
                    ))}
                  </>
                </CustomDropdown>
              </FlexRow>
            </>
          )}
          <Spacer bottom="10px" />
          <FlexRow>
            <CustomSLButton
              css={{ padding: "0 10px 0 10px" }}
              primary
              disabled={isAddBtnDisabled}
              onClick={() => {
                handleAddHardwareMMID();
              }}
            >
              Add
            </CustomSLButton>
            <CustomSLButton secondary onClick={() => setAddHardwareModal(false)}>
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </DialogModal>
    </>
  );
};
