import React, { useState, useContext, useEffect, useRef } from "react";
import { SLButton } from "../../../components/core/Controls";
import { FlexRow, Spacer, SmallText } from "../../../components/core/Utility/index";
import "./../Stepper.css";
import { CustomDropdown, CustomInputDynamicWidth } from "./../CreateNewJob";
import { AccountDetailsContext } from "../../../context/accountDetailsContext";
import { SLNotification } from "../../../components/core/utility";
import { generate } from "shortid";
import { css } from "@emotion/react";
import { Table, TableHead, TableHeader } from "@vds/tables";
import { Loader } from "@vds/loaders";
import Select from "react-select";
import { Icon } from "@vds/icons";
import styled from "@emotion/styled";
import { COLUMN_WIDTHS } from "../../../constants/ApplicationConstants";
import { getNoShowData } from "../../../services/scheduler/getNoShowData";
import { getReasonsData } from "../../../services/scheduler/getReasonsData";
import useOutsideClick from "../../../customHook/use-outside-click";

const CustomTableHeader = css`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    /* border-bottom: 2px solid #000000; */
    border-bottom: none;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
      /* padding: 1rem 0.5rem 0.75rem 0.5rem; */
    }
  }
  tr {
    border-bottom: none;
    td {
      padding: 8px 2px 5px 0px;
      /* padding: 8px 2px 5px 15px; */
    }
  }
`;
const CustomSelectCSS = css`
  input {
    min-width: 55vh !important;
  }
`;

export const IconWrapper = styled.div`
  :focus {
    border: 1px solid black !important;
    outline: none;
    box-shadow: none;
  }
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
`;

const SpecialItems = ({
  formStep,
  previousStep,
  nextStep,
  // stepsEnable,
  closePopup,
  jobDetails,
  stepModalFocus,
}) => {
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState();
  const feeNotification = useRef(false);
  const { specialItemFeeDesc, specialItemsFees } = useContext(AccountDetailsContext);
  const cspSessionData = JSON.parse(sessionStorage.getItem("CSPDetailsSchedulingPortal"));
  const [nextEnable, setNextEnable] = useState(true);
  const [noShowData, setNoShowData] = useState([]);
  /* eslint-disable no-console */

  const [noShowReasons, setNoShowReasons] = useState([]);
  const [adjustmentReasons, setAdjustmentReasons] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const customStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid gray" : "",
      // This line disable the blue border
      boxShadow: state.isFocused ? "1px solid gray" : "",
      "&:hover": {
        border: state.isFocused ? "1px solid gray" : "",
      },
    }),
  };
  const handleClickOutside = () => {
    if (feeNotification.current === false) setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  const columns = [
    {
      key: "Label",
      value: "label",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Value",
      value: "value",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Text",
      value: "text",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Unit",
      value: "baseMeasurementUnit",
    },
  ];

  const handleSelectChange = (option) => {
    if (specialItemsFees.specialItemsFees?.length === 0) addMoreFees(option);
  };

  const deleteRow = (index) => {
    setSelectedOption(null);
    const tempArray = specialItemsFees.specialItemsFees.filter((item, i) => i !== index);
    specialItemsFees.setSpecialItemsFees(tempArray);
  };

  const getMaterialsData = async () => {
    setLoader(true);
    let payload = { salesOrgId: jobDetails.salesOrg, materialType: "Service" };
    let materialsResponse = await getNoShowData(payload);
    if (materialsResponse.status === 200 && materialsResponse.data?.data?.length) {
      let materialInfo = materialsResponse?.data?.data;
      let detailsList = [];
      materialInfo?.map((item) => {
        let details = {
          label: `${item.materialName}-${item.materialId}`,
          value: item.materialId,
          text: item.materialName,
          baseMeasurementUnit: item.baseMeasurementUnit,
          fee: item.materialFunction[0].fee,
        };
        detailsList.push(details);
      });
      setNoShowData(detailsList);
      setLoader(false);
    } else {
      setLoader(false);
      notifyError("Unable to fetch Processing Codes due to an unexpected error.", "error");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const fetchReasonsData = async () => {
    setLoader(true);
    let reasonsResponse = await getReasonsData();
    if (reasonsResponse.status === 200 && reasonsResponse.data?.data?.length) {
      const data = reasonsResponse.data?.data;
      const tempNoShowReasons = data.filter((data) => data.category === "NO_SHOW" && data.subCategory === "MAIN_REASON");
      setNoShowReasons(tempNoShowReasons);

      const tempAdjustmentReasons = data.filter((data) => data.category === "NO_SHOW" && data.subCategory === "ADJUSTMENT");
      setAdjustmentReasons(tempAdjustmentReasons);

      setLoader(false);
    } else {
      setLoader(false);
      notifyError("Unable to fetch Processing Codes due to an unexpected error.", "error");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  //for accessibility
  const addFeesref = useRef();
  const InstallPartnerFeesTitle = useRef();

  useEffect(() => {
    //for accessibility
    InstallPartnerFeesTitle.current.focus();

    getMaterialsData();
    fetchReasonsData();
  }, []);

  useEffect(() => {
    let specialItemErrorArr = [];
    specialItemsFees.specialItemsFees &&
      specialItemsFees.specialItemsFees?.forEach((obj) => {
        let allTrue = Object.entries(obj).filter((el) => {
          if (
            el[0] !== "vendorQtyFlag" &&
            el[0] !== "customerQtyFlag" &&
            el[0] !== "adjustmentReasonFlag" &&
            el[0] !== "adjustmentReason" &&
            el[0] !== "unit" &&
            el[0] !== "fee"
          ) {
            return el[1].success !== true;
          }
        });
        if (allTrue.length) {
          specialItemErrorArr.push(obj);
        }
      });

    if (specialItemErrorArr.length === 0) {
      setNextEnable(false);
    } else {
      setNextEnable(true);
    }
  }, [specialItemsFees.specialItemsFees]);

  const handleFields = (field, value, index = null) => {
    switch (field) {
      case "itemDescription": {
        let tempFees = [...specialItemsFees.specialItemsFees];
        const tempData = specialItemFeeDesc && specialItemFeeDesc?.specialItemFeeDesc.filter((data) => data.materialName === value);
        const data = tempData[0].materialId;
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Select Description", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Select Description", success: false };
        }
        tempFees[index]["mmid"] = { value: data, error: false, errorText: "Enter MMID", success: true };
        tempFees[index]["unit"] = { value: tempData[0].baseMeasurementUnit, error: false, errorText: "Enter Unit of Measure", success: true };
        specialItemsFees.setSpecialItemsFees(tempFees);
        break;
      }

      case "mmid": {
        let tempFees = [...specialItemsFees.specialItemsFees];
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Enter MMID", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Enter MMID", success: false };
        }
        specialItemsFees.setSpecialItemsFees(tempFees);
        break;
      }

      case "noShowReason": {
        let tempFees = [...specialItemsFees.specialItemsFees];
        const tempNoShowReason = noShowReasons.filter((data) => data.description === value);
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Select Code", success: true, id: tempNoShowReason[0].id };
          tempFees[index]["vendorQtyFlag"] = false;

          if (tempNoShowReason[0].subCode === "CUSTOMER") {
            let data = tempFees[index]["vendorQuantity"].value;
            tempFees[index]["customerQtyFlag"] = true;
            if (Number(data) > 0) {
              tempFees[index]["customerQuantity"] = { value: data, error: false, errorText: "Enter Quantity", success: true };
            } else {
              tempFees[index]["customerQuantity"] = { value: data, error: false, errorText: "Enter Quantity", success: false };
            }
            if (data != "") {
              tempFees[index]["adjustmentReasonFlag"] = false;
            } else {
              tempFees[index]["adjustmentReasonFlag"] = true;
            }
            tempFees[index]["adjustmentReason"] = { value: "", error: false, errorText: "Select Code", success: true };
          } else if (tempNoShowReason[0].subCode === "COMPANY") {
            let data = "0";
            tempFees[index]["customerQtyFlag"] = true;
            if (tempFees[index]["vendorQuantity"].value) {
              tempFees[index]["customerQuantity"] = { value: data, error: false, errorText: "Enter Quantity", success: true };
            } else {
              tempFees[index]["customerQuantity"] = { value, error: false, errorText: "Enter Quantity", success: true };
            }

            if (Number(tempFees[index]["vendorQuantity"].value) === 0) {
              tempFees[index]["vendorQuantity"] = {
                value: tempFees[index]["vendorQuantity"].value,
                error: true,
                errorText: "Qty greater than 0",
                success: false,
              };
            } else {
              // tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };
            }

            tempFees[index]["adjustmentReasonFlag"] = true;
            tempFees[index]["adjustmentReason"] = { value: "", error: false, errorText: "Select Code", success: true };
          }
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Select Code", success: false };
        }
        specialItemsFees.setSpecialItemsFees(tempFees);
        break;
      }
      case "vendorQuantity": {
        let tempFees = [...specialItemsFees.specialItemsFees];
        const tempNoShowReason = noShowReasons.filter((data) => data.description === tempFees[index]["noShowReason"].value);
        if (value && value >= 0 && value <= 100) {
          if (tempNoShowReason[0].subCode === "CUSTOMER") {
            tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };

            tempFees[index]["adjustmentReasonFlag"] = false;
            if (Number(value) > 0) {
              tempFees[index]["customerQuantity"] = { value, error: false, errorText: "Enter Quantity", success: true };
            } else {
              tempFees[index]["customerQuantity"] = { value, error: false, errorText: "Enter Quantity", success: false };
            }
          } else if (tempNoShowReason[0].subCode === "COMPANY") {
            let data = "0";
            if (Number(value) === 0) {
              tempFees[index][field] = { value, error: true, errorText: "Qty greater than 0", success: false };
            } else {
              tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };
            }

            tempFees[index]["customerQuantity"] = { value: data, error: false, errorText: "Enter Quantity", success: true };
          }
        } else {
          tempFees[index][field] = { value: 0, error: true, errorText: "Enter Valid Quantity", success: false };
          tempFees[index]["adjustmentReasonFlag"] = true;
          tempFees[index]["customerQtyFlag"] = true;
        }

        specialItemsFees.setSpecialItemsFees(tempFees);
        break;
      }
      case "customerQuantity": {
        let tempFees = [...specialItemsFees.specialItemsFees];
        if (value && value >= 0 && value <= 100) {
          const tempAdjustReason = adjustmentReasons.filter((data) => data.description === tempFees[index]["adjustmentReason"].value);
          if (tempFees[index]["vendorQtyFlag"]) {
            if (tempAdjustReason[0].subCode === "CUST_FEE") {
              if (value < Number(tempFees[index]["vendorQuantity"].value)) {
                tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };
              } else {
                tempFees[index][field] = { value, error: true, errorText: "Qty should be lesser", success: false };
              }
            } else if (tempAdjustReason[0].subCode === "VENDOR_FEE") {
              if (value > Number(tempFees[index]["vendorQuantity"].value)) {
                tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };
              } else {
                tempFees[index][field] = { value, error: true, errorText: "Qty should be greater", success: false };
              }
            }
          }
        } else {
          tempFees[index][field] = { value: 0, error: true, errorText: "Enter Valid Quantity", success: false };
        }

        specialItemsFees.setSpecialItemsFees(tempFees);
        break;
      }
      case "adjustmentReason": {
        let tempFees = [...specialItemsFees.specialItemsFees];
        const tempAdjustReason = adjustmentReasons.filter((data) => data.description === value);
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Select Code", success: true, id: tempAdjustReason[0].id };
          if (tempAdjustReason[0].subCode === "CUST_FEE") {
            tempFees[index]["vendorQtyFlag"] = true;
            tempFees[index]["customerQtyFlag"] = false;
            if (Number(tempFees[index]["customerQuantity"].value) < Number(tempFees[index]["vendorQuantity"].value)) {
              // let data = tempFees[index]["customerQuantity"].value;
              // tempFees[index]["customerQuantity"] = { value: data, error: false, errorText: "Enter Quantity", success: true };
            } else {
              let data = tempFees[index]["vendorQuantity"].value;
              tempFees[index]["customerQuantity"] = { value: data, error: true, errorText: "Qty should be lesser", success: false };
            }
          } else if (tempAdjustReason[0].subCode === "VENDOR_FEE") {
            tempFees[index]["vendorQtyFlag"] = true;
            tempFees[index]["customerQtyFlag"] = false;
            if (Number(tempFees[index]["customerQuantity"].value) > Number(tempFees[index]["vendorQuantity"].value)) {
              // let data = tempFees[index]["customerQuantity"].value;
              // tempFees[index]["customerQuantity"] = { value: data, error: false, errorText: "Enter Quantity", success: true };
            } else {
              let data = tempFees[index]["vendorQuantity"].value;
              tempFees[index]["customerQuantity"] = { value: data, error: true, errorText: "Qty should be greater", success: false };
            }
          }
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Select Code", success: false };
        }
        specialItemsFees.setSpecialItemsFees(tempFees);
        break;
      }
    }
  };

  const addMoreFees = (option) => {
    let tempFees = specialItemsFees.specialItemsFees?.length > 0 ? [...specialItemsFees.specialItemsFees] : [];
    tempFees.push({
      itemDescription: { value: "", error: false, errorText: "Select Description", success: true },
      mmid: { value: "", error: false, errorText: "Enter MMID", success: false },
      noShowReason: { value: "", error: false, errorText: "Select Code", success: false, id: "" },
      vendorQuantity: { value: "", error: false, errorText: "Enter Quantity", success: false },
      customerQuantity: { value: "", error: false, errorText: "Enter Quantity", success: false },
      adjustmentReason: { value: "", error: false, errorText: "Select Code", success: false, id: "" },
      vendorQtyFlag: true,
      customerQtyFlag: true,
      adjustmentReasonFlag: true,
      unit: option.baseMeasurementUnit,
      fee: option.fee,
    });

    let desc = option.text;
    let mmid = option.value;
    tempFees[specialItemsFees.specialItemsFees?.length]["itemDescription"] = {
      value: desc,
      error: false,
      errorText: "Select Description",
      success: true,
    };
    tempFees[specialItemsFees.specialItemsFees?.length]["mmid"] = { value: mmid, error: false, errorText: "Enter MMID", success: true };
    specialItemsFees.setSpecialItemsFees(tempFees);
    //for accessibility
    // addFeesref.current.focus();
  };

  const notifyError = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const previousStepModalFocus = () => {
    previousStep();
    // stepModalFocus();
  };

  return (
    <>
      <Spacer top="4vw" />
      <FlexRow style={{ justifyContent: "space-between", padding: "5px", fontFamily: "" }}>
        <div
          ref={InstallPartnerFeesTitle}
          tabIndex={0}
          style={{ fontSize: "2rem", fontWeight: 700, color: "#000", marginTop: "0.5em", display: "inline-block" }}
        >
          Service Items
        </div>
      </FlexRow>
      {notification}
      <Spacer top="1vw" bottom=".7rem">
        <div style={{ padding: "5px", paddingBottom: "0px", width: "50%", fontSize: "13px" }}>
          {specialItemsFees.specialItemsFees?.length === 0 ? (
            <Select
              options={noShowData}
              onChange={handleSelectChange}
              isSearchable={false}
              css={CustomSelectCSS}
              styles={customStyle}
              value={selectedOption}
            />
          ) : (
            <Select
              options={noShowData}
              onChange={handleSelectChange}
              isSearchable={true}
              css={CustomSelectCSS}
              styles={customStyle}
              isDisabled={true}
            />
          )}
        </div>
      </Spacer>
      <div style={{ fontSize: "13px", fontWeight: "600" }}>*Customer No Show fees are not applicable to NASPO and GSA contracts.</div>
      <FlexRow style={{ padding: "5px", marginBottom: "0px", paddingBottom: "0px", marginTop: ".5vw" }}>
        {specialItemsFees.specialItemsFees && specialItemsFees.specialItemsFees.length > 0 && (
          <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader}>
            <TableHead role="row">
              <TableHeader role="columnheader" width="38%">
                <SmallText bold>Item Description</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="20%">
                <SmallText bold>MMID</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="30%">
                <SmallText bold>No Show Reason</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="15%">
                <SmallText bold>Vendor Quantity</SmallText>{" "}
              </TableHeader>
              <TableHeader role="columnheader" width="16%">
                <SmallText bold>Customer Quantity</SmallText>{" "}
              </TableHeader>
              <TableHeader role="columnheader" width="32%">
                <SmallText bold>Adjustment Reason</SmallText>
              </TableHeader>
            </TableHead>
          </Table>
        )}

        <Loader active={isLoading} fullscreen={false} />
        <div css={{ height: "15vh" }}>
          {specialItemsFees.specialItemsFees &&
            specialItemsFees.specialItemsFees.length > 0 &&
            specialItemsFees.specialItemsFees.map((el, index) => {
              return (
                <FlexRow key={index} style={{ justifyContent: "space-between", padding: "5px", paddingBottom: "0px", paddingLeft: "0px" }}>
                  <CustomInputDynamicWidth
                    width="21%"
                    type="text"
                    disabled
                    value={el.itemDescription.value}
                    error={el.itemDescription.error}
                    errorText={el.itemDescription.errorText}
                    success={el.itemDescription.success}
                    paddingRight="5px"
                  />
                  <CustomInputDynamicWidth
                    width="13%"
                    type="text"
                    name="mmid"
                    disabled
                    value={el.mmid.value}
                    error={el.mmid.error}
                    errorText={el.mmid.errorText}
                    success={el.mmid.success}
                    paddingRight="5px"
                  />
                  <CustomDropdown
                    width="23%"
                    type="text"
                    value={el.noShowReason.value || "Please select"}
                    error={el.noShowReason.error}
                    errorText={el.noShowReason.errorText}
                    success={el.noShowReason.success}
                    onChange={(e) => handleFields("noShowReason", e.target.value, index)}
                    style={{ fontSize: ".75rem" }}
                  >
                    <>
                      <option disabled>Please select</option>
                      {noShowReasons.map((type) => (
                        <option key={type.id}>{type.description}</option>
                      ))}
                    </>
                  </CustomDropdown>
                  <CustomInputDynamicWidth
                    width="5%"
                    type="number"
                    value={el.vendorQuantity.value}
                    error={el.vendorQuantity.error}
                    errorText={el.vendorQuantity.errorText}
                    success={el.vendorQuantity.success}
                    onChange={(e) => handleFields("vendorQuantity", e.target.value, index)}
                    paddingRight="5px"
                    disabled={el.vendorQtyFlag}
                  />
                  <CustomInputDynamicWidth
                    width="5%"
                    type="number"
                    value={el.customerQuantity.value}
                    error={el.customerQuantity.error}
                    errorText={el.customerQuantity.errorText}
                    success={el.customerQuantity.success}
                    onChange={(e) => handleFields("customerQuantity", e.target.value, index)}
                    paddingRight="5px"
                    disabled={el.customerQtyFlag}
                  />
                  <CustomDropdown
                    width="22%"
                    type="text"
                    value={el.adjustmentReason.value || "Please select"}
                    error={el.adjustmentReason.error}
                    errorText={el.adjustmentReason.errorText}
                    success={el.adjustmentReason.success}
                    onChange={(e) => handleFields("adjustmentReason", e.target.value, index)}
                    disabled={el.adjustmentReasonFlag}
                    style={{ fontSize: ".75rem" }}
                  >
                    <>
                      <option disabled>Please select</option>
                      {adjustmentReasons.map((type) => (
                        <option key={type.id}>{type.description}</option>
                      ))}
                    </>
                  </CustomDropdown>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "start" }} onClick={() => deleteRow(index)}>
                    <Icon
                      className="trash-icon"
                      name="trash"
                      size="medium"
                      color="#000000"
                      style={{ cursor: "pointer", outline: "none", marginTop: "7px" }}
                    />
                  </div>
                </FlexRow>
              );
            })}
        </div>
      </FlexRow>
      <FlexRow style={{ justifyContent: "flex-start", marginTop: "0.5rem" }}>
        {formStep > 0 && (
          <SLButton
            aria-label="Previous"
            role="button"
            size="large"
            style={{ width: "10rem", marginTop: "5px", marginBottom: "0px" }}
            onClick={previousStepModalFocus}
          >
            Previous
          </SLButton>
        )}
        <SLButton
          aria-label="Next"
          role="button"
          size="large"
          style={{ width: "10rem", marginTop: "5px", marginBottom: "0px" }}
          // disabled={
          //   cspSessionData?.Account_Brand.toLowerCase() === "gsa" || cspSessionData?.Account_Brand.toLowerCase() === "naspo"
          //     ? false
          //     : specialItemsFees.specialItemsFees.length === 0
          //     ? true
          //     : nextEnable && specialItemsFees.specialItemsFees.length > 0
          // }
          // aria-disabled={
          //   cspSessionData?.Account_Brand.toLowerCase() === "gsa" || cspSessionData?.Account_Brand.toLowerCase() === "naspo"
          //     ? false
          //     : specialItemsFees.specialItemsFees.length === 0
          //     ? true
          //     : nextEnable && specialItemsFees.specialItemsFees.length > 0
          // }
          disabled={specialItemsFees.specialItemsFees.length === 0 ? false : nextEnable && specialItemsFees.specialItemsFees.length > 0}
          aria-disabled={specialItemsFees.specialItemsFees.length === 0 ? false : nextEnable && specialItemsFees.specialItemsFees.length > 0}
          onClick={nextStep}
        >
          Next
        </SLButton>
        <SLButton aria-label="Cancel" role="button" size="large" secondary style={{ width: "10rem", marginBottom: "0px" }} onClick={closePopup}>
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
};
export default SpecialItems;
