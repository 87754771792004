import React, { lazy, useState, useEffect } from "react";

import { FlexRow, Header, SmallText } from "../../components/core/utility";
import { Button } from "@vds/buttons";
import styled from "styled-components";
import { GridComponent } from "../../components/core/Table/GridComponent";

import searchIcon from "../../assets/icons/search_blk.png";
import { INSTALLER_LIST_COL, VENDORS_LIST_COL } from "./VendorInformationConstants";
import { Cell, Table, TableBody, TableHead, TableHeader, TableRow } from "@vds/tables";
import moment from "moment";
import _ from "lodash";
import { Parser } from "json2csv";
import { SLNotification } from "../../components/core/utility";
import { generate } from "shortid";

import { getInstallers } from "../../services/scheduler/getInstallers";
import { Loader } from "@vds/loaders";
import { AddInstallerModal } from "./AddInstallerModal";
import { VendorsMetadata } from "./Vendors";
import { getSyncToMapData } from "../C4S Decommission - Replacement/utils";
import { installersMapping } from "../../services/scheduler/installersMapping";
import useOutsideClick from "../../customHook/use-outside-click";

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid black;
  width: 220px;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const VendorInfo = styled.div`
  border: #d8dada solid 1px;
  padding-left: 24px;
`;

const BorderLessTableRow = styled(TableRow)`
  border-bottom: none !important;
`;

const BorderLessTable = styled(Table)`
  border-top: none !important;
  margin-bottom: -15px;
  margin-top: -15px;
  border-collapse: unset !important;
`;

const CustomButton = styled(Button)`
  >span: nth-child(2n) {
    padding: 0.6875rem 1.75rem !important;
  }
`;

export default function Installers(props) {
  const [isLoading, setLoader] = useState(false);
  const [installersList, setInstallersList] = useState([]);
  const [installersListResponse, setInstallersListResponse] = useState([]);
  const [notification, setNotification] = useState(null);
  const vendorDetails = props.data;
  const [editInstallerRecord, setEditInstallerRecord] = useState({});
  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);

  let data = [
    {
      installerId: "700000001",
      installerName: "CB DIRECT - TX",
      active: "Yes",
      phone: "(123) 689-7907",
      address: "123 WestBury lane",
      city: "Austin",
      state: "Texas",
      country: "USA",
    },
    {
      installerId: "700000023",
      installerName: "CB DIRECT - MA",
      active: "Yes",
      phone: "(123) 689-7907",
      address: "230 Peachtree Blvd",
      city: "Boston",
      state: "Massachusetts",
      country: "USA",
    },
    {
      installerId: "700000011",
      installerName: "CB DIRECT - CA",
      active: "Yes",
      phone: "(123) 689-7907",
      address: "829 Webbridge Industrial",
      city: "California",
      state: "California",
      country: "USA",
    },
  ];

  const notify = (content, type, isNotificationWithTimer = false) => {
    setNotification(
      <>
        <SLNotification key={generate()} type={type} title={content} fullBleed={false} ref={clickRef} />
      </>
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  useEffect(() => {
    getInstallerList(props.data.id);
  }, []);

  const getInstallerList = async (vendorId) => {
    setLoader(true);
    let response = await getInstallers(vendorId);
    if (response.status === 200 && response?.data?.data) {
      let installerList = response?.data?.data.map((installer) => {
        return { ...installer, active: installer?.status, address: installer.address1 };
      });
      setInstallersListResponse(response?.data?.data);
      setInstallersList(installerList);
    } else {
      notify("Unable to fetch the Installers", "error");
    }
    setLoader(false);
  };

  const [globalSearchValue, setGlobalSearchValue] = useState("");
  const [isInstallerModalOpened, setIsInstallerModalOpened] = useState(false);

  const onGlobalSearchChange = (e) => {
    setGlobalSearchValue(e.target.value);
  };

  const onHyperLinkClick = (data) => {
    setEditInstallerRecord(data);
    setIsInstallerModalOpened(true);
  };

  const exportCSV = () => {
    const filename = "Installers" + "_" + moment().format("MM-DD-YYYY");
    let dataToExport = installersList;
    let fieldsToExclude = INSTALLER_LIST_COL.map((col) => {
      if (col.containsImage) {
        return col.value;
      }
    });
    let fieldsToExport = _.cloneDeep(dataToExport).map((data) => _.omit(data, fieldsToExclude));
    const exportColumnsForCSV = INSTALLER_LIST_COL.filter((col) => !col.containsImage).map((col) => {
      return { label: col.key, value: col.value };
    });
    const json2csvParser = new Parser({ fields: exportColumnsForCSV });
    const csv = json2csvParser.parse(fieldsToExport);
    saveFile(csv, filename ?? "data", "text/csv", ".csv");
  };

  const saveFile = (buffer, fileName, type, extension) => {
    import("file-saver").then((module) => {
      if (module?.default) {
        const data = new Blob([buffer], {
          type: type,
        });

        module.default.saveAs(data, fileName + extension);
      }
    });
  };

  // const addNewInstaller = () => {
  //   props.pageChangeCallback(AddInstallerDummyMetadata.id, null);
  // };

  const cancel = () => {
    props.pageChangeCallback(VendorsMetadata.id, null);
  };

  const handleSyncToMap = async () => {
    let payload = {
      vendorName: vendorDetails?.vendorName ?? "",
      vendorStatus: vendorDetails?.active ?? "",
      salesOrgs: vendorDetails?.salesOrg,
      installerLocations: getSyncToMapData(installersListResponse),
    };

    let missingFieldsArray = [];
    for (const key of Object.keys(payload?.installerLocations)) {
      if (key === "status" || key === "installerId") {
        if (payload?.installerLocations[key] === "" || payload?.installerLocations[key] === undefined || payload?.installerLocations[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    for (const key of Object.keys(payload)) {
      if (key === "vendorName" || key === "vendorStatus") {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to sync to map due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }
    setLoader(true);
    if (installersList?.length) {
      let response = await installersMapping(payload);
      if (response?.status === 200 && response?.data?.message?.toLowerCase() === "success") {
        notify("Synced To Map successfully", "success");
      } else if (response?.data?.statusCode === "404") {
        notify(response?.data?.message, "error", false);
      } else {
        notify("We are currently experiencing issues, please try again", "error", false);
      }
      setLoader(false);
    } else {
      notify("No Installers Available.", "error");
      setLoader(false);
    }
  };

  return (
    <div style={{ width: "-webkit-fill-available" }}>
      <Loader active={isLoading} fullscreen />
      {notification}
      {isInstallerModalOpened && (
        <AddInstallerModal
          isInstallerModalOpened={isInstallerModalOpened}
          setIsInstallerModalOpened={setIsInstallerModalOpened}
          vendorDetails={vendorDetails}
          editInstallerRecord={editInstallerRecord}
          setEditInstallerRecord={setEditInstallerRecord}
          getInstallerList={getInstallerList}
        />
      )}
      <Header>Installers</Header>
      <FlexRow marginTop="30px" justifyContent="space-between">
        <FlexRow>
          <CustomButton style={{ marginRight: "20px" }} size="large" onClick={() => setIsInstallerModalOpened(true)}>
            Add New Installer
          </CustomButton>
          <CustomButton size="large" secondary onClick={exportCSV}>
            Download
          </CustomButton>
        </FlexRow>
        <SearchInputWrapper>
          <SearchInput
            role="search"
            aria-label={globalSearchValue}
            value={globalSearchValue}
            type="text"
            onChange={onGlobalSearchChange}
            placeholder="Search"
          ></SearchInput>
          <img aria-label="search" role="img" src={searchIcon} height="20px" width="20px"></img>
        </SearchInputWrapper>
      </FlexRow>
      <br />
      <VendorInfo>
        <BorderLessTable role="table" striped={false}>
          <TableHead role="row">
            {props?.data &&
              Object.entries(props.data)
                .filter((entry) =>
                  VENDORS_LIST_COL.filter((col) => col.value != "installer")
                    .map((col) => col.value)
                    .includes(entry[0])
                )
                .map((entry) => (
                  <TableHeader role="columnheader" key={entry[0]}>
                    <SmallText bold>{VENDORS_LIST_COL.find((col) => col.value === entry[0])?.key}</SmallText>
                  </TableHeader>
                ))}
          </TableHead>
          <TableBody role="rowgroup">
            <BorderLessTableRow role="row">
              {props?.data &&
                Object.entries(props.data)
                  .filter((entry) =>
                    VENDORS_LIST_COL.filter((col) => col.value != "installer")
                      .map((col) => col.value)
                      .includes(entry[0])
                  )
                  .map((entry) =>
                    entry[1]?.toLowerCase() === "sync to map" ? (
                      <Cell role="cell" key={entry[1]} onClick={handleSyncToMap}>
                        <SmallText css={{ textDecoration: "underline", cursor: "pointer" }} aria-label={entry[1]}>
                          {entry[1] ?? "--"}
                        </SmallText>
                      </Cell>
                    ) : (
                      <Cell role="cell" key={entry[1]}>
                        <SmallText aria-label={entry[1]}>{entry[1] ?? "--"}</SmallText>
                      </Cell>
                    )
                  )}
            </BorderLessTableRow>
          </TableBody>
        </BorderLessTable>
      </VendorInfo>
      <GridComponent
        rows={installersList}
        columns={INSTALLER_LIST_COL}
        paginator={true}
        hideGlobalFilter={true}
        hyperLinkClicked={onHyperLinkClick}
        searchKeyboardValue={globalSearchValue}
        whiteSpace="unset"
        itemsPerPageDropDownValues={[
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 },
          { label: "All", value: installersList.length },
        ]}
      />
      <br />
      <CustomButton size="large" secondary onClick={cancel}>
        Cancel
      </CustomButton>
    </div>
  );
}

export const InstallersMetadata = {
  name: "Installers Table",
  id: "Installers_Table",
  component: lazy(() => import("./Installers")),
  route: "/installers",
};
