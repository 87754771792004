import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import Icon from "@vds/icons";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, SmallText } from "../../components/core/utility";
import { C4SContext } from "../../context/c4sContext";
import { createWorkticket } from "../../services/C4S Decommission - Replacement/createWorkticket";
import { generate } from "shortid";
import { Loader } from "@vds/loaders";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

const CreateWorkTicketModal = ({
  createWorkTicketModal,
  setCreateWorkTicketModal,
  handleWorkTicketCreation,
  quantityValidationCheck,
  lostInTransit,
}) => {
  const { cspDetails, notification, source } = useContext(C4SContext);
  const [isLoading, setLoader] = useState(false);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setCreateWorkTicketModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const createWorkticketRevrecCheck = () => {
    setCreateWorkTicketModal(false);
    if (lostInTransit || source.source.toLowerCase() === "case") {
      handleWorkTicketCreation();
    } else {
      quantityValidationCheck();
    }
  };

  return (
    <>
      <Loader active={isLoading} fullscreen />
      <Modal
        role="dialog"
        ariaLabel="Create Work Ticket"
        hideCloseButton={false}
        opened={createWorkTicketModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              <Title size="medium">Create Work Ticket</Title>
            </div>
            <div
              onClick={() => {
                setCreateWorkTicketModal(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          <SmallText>By submitting, you confirm that the information you entered is correct.</SmallText>
        </ModalBody>
        <ModalFooter>
          <FlexRow style={{ paddingTop: "2rem" }}>
            <CustomSLButton
              primary
              aria-label="Create Work Ticket"
              role="button"
              onClick={() => {
                createWorkticketRevrecCheck();
              }}
            >
              Create Work Ticket
            </CustomSLButton>
            <CustomSLButton
              secondary
              aria-label="Cancel"
              role="button"
              onClick={() => {
                setCreateWorkTicketModal(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default CreateWorkTicketModal;
