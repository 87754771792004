import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Icon } from "@vds/icons";
import { Title, Body } from "@vds/typography";
import { useState } from "react";
import { SLButton } from "../../components/core/Controls";
import { FlexRow, Spacer } from "../../components/core/Utility/index";
import { StyledModal } from "./RescheduleModal";
import { Dropdown } from "primereact/dropdown";
import { CustomisedInput } from "./CompleteJob";
import { DropdownSelect } from "@vds/selects";
import styled from "styled-components";
import { SmallText } from "../../components/core/utility";
import { TextArea } from "@vds/inputs";
import { updateNotes } from "../../services/scheduler/updateNotes";
import { getDropDownData } from "../../services/scheduler/getDropDownData";

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    height: 2rem;
    & span {
      & span {
        & div {
          & select {
            padding: 0.2rem 1rem 0.2rem 0.5rem;
            font-size: 12px;
            color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
            text-wrap: wrap;
            line-height: 0.8rem;
            &:disabled {
              -webkit-text-fill-color: #000000;
              background-color: #ffffff;
              border-bottom: 1px solid #000000;
              opacity: ${(props) => (props.disabled ? "0.4" : "")};
            }
          }
          & span {
            top: 0.35rem;
            right: 0.5rem;
            & svg {
              width: 0.8rem;
              height: 0.8rem;
              min-width: 0.8rem;
              min-height: 0.8rem;
              display:${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
        & div {
          & div {
            & p {
              display: none;
            }
          }
        }
      }
      }
    }
  }
`;

const RescheduleReasonModal = ({
  rescheduleReasonModalStatus,
  setRescheduleReasonModalStatus,
  handleReschedule,
  handleRescheduleManual,
  rescheduleDate,
  jobDetails,
  notify,
}) => {
  const [reason, setReason] = useState({});
  const [internalNotes, setInternalNotes] = useState(jobDetails.internalNotes);
  const changeRescheduleReason = (e) => {
    let selectedReason = rescheduleReasonModalStatus.reasonList.filter((item) => item.description === e.target.value)[0];
    setReason(selectedReason);
  };

  const handleInternalNotes = (notes) => {
    setInternalNotes(notes);
  };
  const handleConfirmation = async () => {
    if (reason.description == "Other") {
      const jobDataresponse = await getDropDownData(jobDetails.id ? jobDetails.id : jobDetails?.jobId);
      if (jobDataresponse?.data?.statusCode == 200 && jobDataresponse?.data?.data?.length > 0) {
        let notesData = {
          schedulingNotes: jobDataresponse.data.data[0]?.schedulingNotes || "",
          vendorMessages: jobDataresponse.data.data[0]?.vendorMessages || "",
          internalNotes: internalNotes,
          modifiedBy: `${JSON.parse(sessionStorage.getItem("FirstName"))} ${JSON.parse(sessionStorage.getItem("LastName"))}` || "",
        };
        const id = jobDetails.id ? jobDetails.id : jobDetails.jobId;
        const data = {
          jobID: id,
          payload: notesData,
        };
        const response = await updateNotes(data);

        if (response?.data?.statusCode == 200) {
          if (rescheduleReasonModalStatus.isManualSchedule) {
            handleRescheduleManual(reason);
          } else {
            handleReschedule(reason);
          }
        } else {
          setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false });
          notify("Unable to update internal notes due to an unexpected error", "error");
        }
      } else {
        setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false });
        notify("Unable to fetch job data due to an unexpected error", "error");
      }
    } else {
      if (rescheduleReasonModalStatus.isManualSchedule) {
        handleRescheduleManual(reason);
      } else {
        handleReschedule(reason);
      }
    }
  };
  return (
    <Modal
      id="Reschedule Reason Modal"
      ariaLabel="Reschedule Reason Modal"
      opened={true}
      fullScreenDialog={false}
      disableOutsideClick={true}
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">Reschedule Job?</Title>
          <div
            onClick={() => setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false })}
            css={{ marginRight: "1rem", marginTop: "0.3rem" }}
          >
            <Icon
              role="img"
              aria-label="close"
              alt="close-icon"
              name="close"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none" }}
            />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        <Body tabIndex={0}>
          The job will be rescheduled for{" "}
          {rescheduleReasonModalStatus.isManualSchedule
            ? rescheduleDate
            : `${rescheduleReasonModalStatus.slotTime} ${rescheduleReasonModalStatus.timezone}`}
        </Body>
        <Spacer top="1rem" />
        <div style={{ padding: "5px", paddingBottom: "0px", width: "70%", fontSize: "13px" }}>
          <CustomDropdown
            type="text"
            tabIndex={0}
            ariaLabel="Reschedule reason"
            value={reason.description || "Please Select"}
            onChange={(e) => changeRescheduleReason(e)}
            placeholder="Please Select"
          >
            <>
              <option disabled>Please Select</option>
              {rescheduleReasonModalStatus.reasonList.map((code) => (
                <option key={code.code}>{code.description}</option>
              ))}
            </>
          </CustomDropdown>
        </div>
        {reason.description == "Other" && (
          <TextArea
            label="Internal Notes"
            aria-label="Internal Notes"
            required={true}
            helperText="Internal Notes"
            helperTextPlacement="bottom"
            maxLength={500}
            value={internalNotes}
            onChange={(e) => handleInternalNotes(e.target.value)}
            css={{
              fontSize: "12px",
              fontFamily: "Verizon-NHG-eTX",
              letterSpacing: "0",
            }}
          />
        )}
      </ModalBody>
      {/* <ModalFooter> */}
      <FlexRow css={{ justifyContent: "flex-end", paddingTop: "6.5rem" }}>
        <SLButton
          aria-label="Confirm"
          role="button"
          size="large"
          css={{ width: "10rem" }}
          onClick={() => {
            handleConfirmation();
          }}
          disabled={!reason.code}
        >
          Confirm
        </SLButton>
        <SLButton
          size="large"
          secondary
          aria-label="Cancel"
          role="button"
          css={{ width: "10rem" }}
          onClick={() => setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false })}
        >
          Cancel
        </SLButton>
      </FlexRow>
      {/* </ModalFooter> */}
    </Modal>
  );
};

export default RescheduleReasonModal;
