import React, { lazy, useEffect, useState, useContext } from "react";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { WORK_VIEW_ITEM_TABLE_COL } from "../../constants/WorkViewItemTableConstants";
import { Header } from "../../components/core/Controls";
import { Accordion, AccordionTab } from "primereact/accordion";
import styled from "styled-components";
import MMIDFlyOutView from "./MMIDFlyOutView";
import { WORK_VIEW_VEHICLE_TABLE_COL } from "../../constants/ViewWorkTicketConstants";
import { C4SContext } from "../../context/c4sContext";
import "./dropdown.css";
import { getFullName } from "./utils";
import { OrderTrackingInfoTable } from "./OrderTrackingTable";
import _ from "lodash";
import { Loader } from "@vds/loaders";
import { SLNotification } from "../../components/core/utility";
import { generate } from "shortid";
import WorkViewItemTable from "./WorkViewItemTable";
import { getWorkTicketMaterialList } from "../../services/C4S Decommission - Replacement/getWorkTicketMaterialList";
import { getWorkTicketVINList } from "../../services/C4S Decommission - Replacement/getWorkTicketVINList";
import { Button } from "@vds/buttons";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";
import { CompactCol, CustomSLButton, FlexRow, SmallText } from "../../components/core/utility";

export const AccordionWrapper = styled.div`
  padding-top: 2.5rem;
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header:hover .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    color: #000000;
    border: none;
    padding: 0.5rem;
    background: #f6f6f6;
    box-shadow: unset;
    border-radius: unset;
  }

  .p-accordion .p-accordion-content {
    padding: 0rem;
    border: none;
  }
  .p-accordion-header-text {
    font-weight: 700;
    font-family: Verizon-NHG-eDS;
  }
`;
const GridComponentWrapper = styled.div`
  .p-datatable-scrollable .p-datatable-wrapper {
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: block !important;
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      display: inline-block !important;
      background-color: #c0c0c0 !important;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
  }
`;

export default function DetailedWorkTicketView(props) {
  const [workTicketData, setWorkTicketData] = useState([]);
  const [workTicketVehicles, setWorkTicketVehicles] = useState([]);
  // const returnTicketListdata = [];
  const { isSourceCSP } = useContext(C4SContext);
  let cspCaseData = JSON.parse(sessionStorage.getItem("CSPDetails")),
    cspCaseOwner = "",
    crmReferenceId = "";
  let cspCaseDetails = {
    assignedTo: null,
    assignedToEmail: null,
    assignedToEid: null,
  };
  if (isSourceCSP.isSourceCSP) {
    cspCaseOwner = cspCaseData.CSP?.filter((cspData) => cspData.DefaultCSP === "1")[0]?.CSPOwner ?? "";
    crmReferenceId = cspCaseData.CSP?.filter((cspData) => cspData.DefaultCSP === "1")[0]?.CRM_Ref_ID ?? "";
    let cspData = cspCaseData.CSP.length ? cspCaseData.CSP.find((item) => item.DefaultCSP === "1") : {};
    cspCaseDetails = {
      assignedTo: cspData?.CSPOwner || null,
      assignedToEmail: cspData?.CSPOwnerEmail || null,
      assignedToEid: cspData?.CSPOwnerEId || null,
    };
  } else {
    cspCaseOwner = cspCaseData.CASEDetails?.filter((caseData) => caseData.DefaultCase === "1")[0]?.CaseOwner ?? "";
    crmReferenceId = cspCaseData.CASEDetails?.filter((caseData) => caseData.DefaultCase === "1")[0]?.CaseNumber ?? "";
    let caseData = cspCaseData.CASEDetails.length ? cspCaseData.CASEDetails.find((item) => item.DefaultCase === "1") : {};
    cspCaseDetails = {
      assignedTo: caseData?.CaseOwner || null,
      assignedToEmail: caseData?.CaseOwnerEmail || null,
      assignedToEid: caseData?.CaseOwnerEId || null,
    };
  }

  const [orderTrackingData, setOrderTrackingData] = useState();
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [vehicleInfoList, setVehicleInfoList] = useState([]);
  const [materialList, setMaterialList] = useState({ ...props.data });
  let limitWT = sessionStorage.getItem("limitWTCreation") == "true" ? true : false;

  const TYPE_DROPDOWN = [
    { description: "Hardware accessory shipment", code: "ZHWA" },
    { description: "Mileage", code: "ZTML" },
    { description: "Customer Fee", code: "ZBTP" },
    { description: "Service-Misc", code: "ZSP1" },
    { description: "Service", code: "ZSPO" },
    { description: "Hardware shipment exchange", code: "ZZHS" },
    { description: "Hardware shipment", code: "ZHWS" },
    { description: "Hardware misc", code: "ZHS1" },
    { description: "Return Hardware", code: "ZZHR" },
    { description: "Return Accessories", code: "ZHAR" },
    { description: "Accessory Shipment Exchange", code: "ZZAE" },
  ];

  useEffect(() => {
    getWorkTicketMateriallist();
  }, []);

  const rowExpansionTemplate = (data) => (
    <MMIDFlyOutView data={data} vehicleInfoList={vehicleInfoList} workTicketData={workTicketData} workticketId={props?.data?.ticketId} />
  );

  useEffect(() => {
    let orderTrackingInfo = {
      plannedQuantity: [1],
      pgiDate: [1],
      selfInstalled: [0],
      installed: [1],
      returnedOrder: [1],
    };
    setOrderTrackingData(orderTrackingInfo);
  }, []);

  const getWorkTicketMateriallist = async (isRefresh = false) => {
    setLoader(true);
    let workTicketId = props.data.ticketId;
    let response = await getWorkTicketMaterialList(workTicketId);
    if (response?.status === 200 && response?.data) {
      response = response.data.data;
      setMaterialList((prev) => ({ ...prev, materials: response }));
      let workTicketListData = response?.map((material) => {
        return {
          id: material.id || "",
          MMID: material.materialId,
          itemDescription: material.materialName,
          type: material.processingCode,
          displayType: getFullName(TYPE_DROPDOWN, material.processingCode),
          contractItem: material.contractName,
          quantity: material.totalQty,
          MmidPgiDate: material.materialId + " - " + props?.data?.pgiDate,
          esns: material.esn,
          trackingNumbers: material.trackingNumbers,
          trackingUrls: material.trackingUrls,
          deliveryBlock: material.deliveryBlock,
          carriers: material.carriers,
          pgiDate: material.pgiDate,
          pgiQty: material.pgiQty,
          salesOrder: material.salesOrder,
          x_purchaseOrder: material.x_purchaseOrder,
          deliveryDocNumber: material.deliveryDocNumber,
          wtMaterialId: material.mid,
          contractId: material.cid,
          sapId: material.sapId,
          techRefId: material.techRefId,
          bundleId: material.bundleId,
          defaultReturnType: material.defaultReturnType,
          customerMaterialCharge: material.customerMaterialCharge,
          materialChargeCurrency: material.materialChargeCurrency,
          totalQty: material.totalQty,
          orderType: material.orderType,
          contractOverride: material.contractOverride,
        };
      });
      setWorkTicketData(workTicketListData);
      isRefresh ? setLoader(false) : getWorkTicketVINlist();
    } else {
      setMaterialList((prev) => ({ ...prev, materials: [] }));
      notify("Unable to fetch material list due to an unexpected error", "error");
      isRefresh ? setLoader(false) : getWorkTicketVINlist();
    }
  };

  const getWorkTicketVINlist = async () => {
    setLoader(true);
    let workTicketId = props.data.ticketId;
    let response = await getWorkTicketVINList(workTicketId);
    if (response?.status === 200 && response?.data) {
      response = response.data.data;
      setVehicleInfoList(response);
      setMaterialList((prev) => ({ ...prev, vins: response }));
      setVehicleInfoList(response);
      let vins =
        response?.length > 0
          ? response?.filter((item) => item.vin || (item.year && item.make && item.model)).map((item, index) => ({ ...item, index: index + 1 }))
          : [];
      setWorkTicketVehicles(vins);
      setLoader(false);
    } else {
      setMaterialList((prev) => ({ ...prev, vins: [] }));
      notify("Unable to fetch VIN list due to an unexpected error", "error");
      setLoader(false);
    }
  };

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  return (
    <>
      <Loader active={isLoading} fullscreen />
      <Header>
        <FlexRow onClick={(event) => event.preventDefault()}>
          {props?.data?.ticketId}
          <Button
            size="small"
            secondary
            aria-label="Refresh"
            role="button"
            aria-disabled={limitWT || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
            disabled={limitWT || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
            css={{ marginLeft: "20px", marginTop: "5px" }}
            onClick={() => {
              getWorkTicketMateriallist(true);
            }}
          >
            Refresh
          </Button>
        </FlexRow>
      </Header>

      {notification}
      <AccordionWrapper>
        <Accordion activeIndex={0} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <AccordionTab header="Items">
            <GridComponentWrapper>
              <GridComponent
                isExportEnabled={false}
                noDataMessage="No data available to display"
                rows={workTicketData}
                hideGlobalFilter={true}
                columns={WORK_VIEW_ITEM_TABLE_COL}
                paginator={false}
                whiteSpace="normal"
                rowExpansionTemplate={rowExpansionTemplate}
              />
            </GridComponentWrapper>
          </AccordionTab>
        </Accordion>
      </AccordionWrapper>
      {/* {!isSourceCSP.isSourceCSP && ( VZC42148-1817
        <AccordionWrapper>
          <Accordion role="region" activeIndex={0} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
            <AccordionTab aria-label="Order Tracking" header="Order Tracking">
              <OrderTrackingInfoTable data={orderTrackingData} />
            </AccordionTab>
          </Accordion>
        </AccordionWrapper>
      )} */}
      <AccordionWrapper>
        <Accordion role="region" activeIndex={5} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <AccordionTab aria-label="Vehicles" header="Vehicles">
            <div tabIndex={0} aria-label="vehicle list table">
              <GridComponent
                isExportEnabled={false}
                noDataMessage="No data available to display"
                rows={workTicketVehicles}
                hideGlobalFilter={true}
                columns={WORK_VIEW_VEHICLE_TABLE_COL}
                paginator={false}
              />
            </div>
          </AccordionTab>
        </Accordion>
      </AccordionWrapper>
      <WorkViewItemTable data={materialList} AccountID={props?.AccountID} ID={props?.ID} userId={props?.userId} setNotification={setNotification} />
    </>
  );
}

export const DetailedWorkTicketViewMetadata = {
  name: "Detailed Work Ticket View",
  id: "Detailed_Work_Ticket_View",
  component: lazy(() => import("./DetailedWorkTicketView")),
  route: "/detailed_work_ticket_view",
};
