import { Modal, ModalBody } from "@vds/modals";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexColumn, FlexRow, Spacer, SmallText, SLNotification } from "../../components/core/utility";
import Icon from "@vds/icons";
import { CustomDropdown, CustomInput } from "./AddAVehicle";
import { C4SContext } from "../../context/c4sContext";
import { generate } from "shortid";
import _ from "lodash";

import { Loader } from "@vds/loaders";
import { getFeatureList } from "../../services/C4S Decommission - Replacement/getFeatureList";
import searchIcon from "../../assets/icons/search_blk.png";
import { getFullName, TYPE_DROPDOWN_VALUES } from "./utils";
import { materialIdSearch } from "../../services/C4S Decommission - Replacement/mmidSearch";
import { PHONE_REGEX } from "../../constants/C4SValidationConstants";
import { getSapIdList } from "../../services/C4S Decommission - Replacement/getSapIdList";
import styled from "styled-components";
import { MultiSelect } from "react-multi-select-component";
import { Dialog } from "primereact/dialog";
import useOutsideClick from "../../customHook/use-outside-click";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  width: 65vw;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const CustomNotification = styled(SLNotification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 20 1 0%;
    }
  }
`;
export const CustomisedInput = styled(MultiSelect)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  font-family: Verizon-NHG-eTX;
  width: 100%;
  box-shadow: none !important;
  border-radius: 0;
  .dropdown-content {
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: absolute;
    z-index: 100000;
    border: unset !important;
  }
  .dropdown-container {
    border: unset !important;
  }
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 8px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
`;

export const DialogModal = styled(Dialog)`
  overflow-y: inherit !important;
  width: 50vw !important;
  .p-dialog-content {
    // height: 83vh !important;
    overflow-y: auto !important;
  }
  [data-testid~="modal-body"] {
    overflow-y: unset !important;
  }

  @media screen and (min-width: 1300px) and (max-width: 1500px) {
    width: 45vw !important;
  }

  @media screen and (min-width: 910px) and (max-width: 1025px) {
    width: 60vw !important;
    margin-left: 50px;
  }
  @media screen and (min-width: 1501px) {
    width: 650px !important;
    // max-height: 72vh !important;
  }
  @media screen and (min-width: 700px) and (max-width: 909px) {
    width: 70vw !important;
    margin-left: 50px;
  }
  // @media (max-height: 750px) {
  //   width: 70vw !important;
  // }
`;

export const AddVehicleMMIDModal = ({
  addVehicleMMIDModal,
  setAddVehicleMMIDModal,
  vehicleMMIDTableList,
  setVehicleMMIDTableList,
  existingItemDetails,
  contractItemList,
  setContractItemList,
  handleVehicle,
  setIsMultiselectAllDeleted,
}) => {
  const [materialId, setMaterialId] = useState({ value: "", error: false, errorText: "Enter valid MMID", success: false });
  const [itemDescription, setItemDescription] = useState({ value: "", error: false, errorText: "Enter valid item description", success: false });
  const [upsell, setUpsell] = useState({ value: "", error: false, errorText: "Enter valid upsell", success: false });
  const [type, setType] = useState({ value: "", error: false, errorText: "Enter valid type", success: false });
  const [techRefID, setTechRefID] = useState({ value: "", error: false, errorText: "Enter valid tech ref id", success: false });
  const [contractItem, setContractItem] = useState({ value: "", error: false, errorText: "Select contract item", success: false });
  const [feature, setFeature] = useState({ value: [], error: false, errorText: "", success: false });
  const [orderType, setOrderType] = useState({ value: "", error: false, errorText: "Enter valid order type", success: false });
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isUpsell, setIsUpsell] = useState(false);
  const [action, setAction] = useState("Add Item");
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [featureValues, setfeatureValues] = useState([]);
  const { cspDetails, techRefIdList, isGovernmentAcc, isSourceCSP } = useContext(C4SContext);
  const [selectedContractItem, setSelectedContractItem] = useState({});
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [isMMIDValidated, setIsMMIDValidated] = useState(false);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [mId, setMId] = useState();
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [filteredTechRefIdList, setFilteredTechRefIdList] = useState([]);
  const [vinMaterialFeaturesList, setVinMaterialFeaturesList] = useState([]);
  let featureList = [];
  const [contractItems, setContractItems] = useState([]);
  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setAddVehicleMMIDModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    let productList = [...new Set(cspDetails?.cspDetails?.product?.map((i) => i.OrderDetailType))];
    setOrderTypeOptions(productList);
  }, [cspDetails]);

  useEffect(() => {
    !isMMIDValidated && setIsFieldDisabled(true);
    if (upsell.value === "No") {
      materialId.success &&
      itemDescription.success &&
      upsell.success &&
      feature.success &&
      contractItem.success &&
      techRefID.success &&
      isMMIDValidated &&
      type.success
        ? setIsSaveBtnDisabled(false)
        : setIsSaveBtnDisabled(true);
    } else {
      materialId.success && itemDescription.success && upsell.success && feature.success && isMMIDValidated && type.success
        ? setIsSaveBtnDisabled(false)
        : setIsSaveBtnDisabled(true);
    }
  }, [
    type.success,
    materialId.success,
    itemDescription.success,
    upsell.value,
    upsell.success,
    contractItem.success,
    techRefID.success,
    feature.success,
    existingItemDetails,
    isMMIDValidated,
  ]);

  useEffect(() => {
    !(featureValues && featureValues?.length > 0) && fetchFeatureList();
    onContractItemClick();
    if (existingItemDetails && Object.keys(existingItemDetails).length) {
      //VZC42148-30
      setAction("Edit Item");
      handleFields("MMID", existingItemDetails?.materialId);
      handleFields("itemDescription", existingItemDetails?.itemDescription);
      handleFields("upsell", existingItemDetails?.upsell);
      handleFields("type", existingItemDetails?.type);
      // handleFields("techRefID", existingItemDetails?.techRefID);
      // let featArray = [];
      // existingItemDetails.feature.forEach(function (num) {
      //   featArray.push(String(num));
      // });

      //handleFields("feature", existingItemDetails?.feature);
      setVinMaterialFeaturesList(existingItemDetails?.vinMaterialFeatures);
      setMId(existingItemDetails?.mId);
      if (existingItemDetails?.orderType && existingItemDetails?.contractItem && existingItemDetails?.materialId) {
        handleTechRefId(existingItemDetails?.orderType, existingItemDetails?.sapId);
        setIsFieldDisabled(false);
        setIsMMIDValidated(true);
      } else {
        handleFields("techRefID", existingItemDetails?.techRefID);
      }
    }
  }, [existingItemDetails]);

  useEffect(() => {
    if (materialId.success && contractItem.success && orderType.success) {
      setIsSearchDisabled(false);
    } else {
      if (materialId.success && upsell.value === "Yes" && orderType.success) {
        setIsSearchDisabled(false);
      } else {
        setIsSearchDisabled(true);
      }
    }
  }, [materialId.success, contractItem.success, orderType.success, upsell.value]);

  const handleFields = (field, value) => {
    switch (field) {
      case "MMID": {
        setIsMMIDValidated(false);
        if (value && value?.length) {
          if (PHONE_REGEX?.test(value)) {
            setMaterialId({ value, error: false, errorText: "Enter valid MMID", success: true });
          } else {
            setMaterialId({ value, error: true, errorText: "Enter valid MMID", success: false });
          }
        } else {
          setMaterialId({ value: "", error: true, errorText: "Enter MMID", success: false });
        }
        break;
      }
      case "itemDescription": {
        if (value && value.length) {
          setItemDescription({ value, error: false, errorText: "", success: true });
        } else {
          setItemDescription({ value, error: true, errorText: "Enter valid item description", success: false });
        }
        break;
      }

      case "upsell": {
        if (value != null || value != "") {
          if (value === "Yes" || value === true) {
            setUpsell({ value: "Yes", error: false, errorText: "", success: true });
            setIsUpsell(true);
          } else {
            setUpsell({ value: "No", error: false, errorText: "", success: true });
            setIsUpsell(false);
          }
        } else {
          setUpsell({ value, error: true, errorText: "Enter valid upsell", success: false });
        }
        break;
      }
      case "type": {
        if (value && value.length) {
          setType({ value, error: false, errorText: "", success: true });
        } else {
          setType({ value, error: true, errorText: "Please select type", success: false });
        }
        break;
      }
      case "orderType": {
        setContractItem({ value: "", error: false, errorText: "", success: false });
        setIsMMIDValidated(false);
        if (value && value.length) {
          setOrderType({ value, error: false, errorText: "", success: true });
          if (value?.toLowerCase() !== "upsell") {
            setUpsell({ value: "No", error: false, errorText: "", success: true });
            setIsUpsell(false);
          } else {
            if (existingItemDetails && Object.keys(existingItemDetails).length) {
              handleFields("upsell", existingItemDetails?.upsell);
            } else {
              setUpsell({ value: "", error: false, errorText: "Enter valid upsell", success: false });
            }
          }
        } else {
          setOrderType({ value, error: true, errorText: "Please select order type", success: false });
        }
        break;
      }

      case "techRefID": {
        if (value && value.length) {
          setTechRefID({ value, error: false, errorText: "", success: true });
        } else {
          setTechRefID({ value, error: true, errorText: "Please select valid Tech Ref ID", success: false });
        }
        break;
      }
      case "contractItem": {
        setIsMMIDValidated(false);
        if (value && value.length) {
          setContractItem({ value, error: false, errorText: "", success: true });
          contractItemList.map((item) => {
            item.contractName === value && setSelectedContractItem(item);
          });
        } else {
          setContractItem({ value, error: true, errorText: "Select contract item", success: false });
        }
        break;
      }
      case "feature": {
        if (value && value.length) {
          value.map((val) => {
            featureList.push(val);
          });
          let lineItemFeaturesList = featureList.map((item) => {
            return { vmfId: "", featureId: item.value };
          });
          setVinMaterialFeaturesList(lineItemFeaturesList);
          setFeature({ value: featureList, error: false, errorText: "", success: true });
        } else {
          setFeature({ value: featureList, error: true, errorText: "Select feature", success: false });
        }
        if (value.length === 0) {
          setIsMultiselectAllDeleted(true);
        } else {
          setIsMultiselectAllDeleted(false);
        }
        break;
      }
    }
  };

  const handleSaveVehicleMMID = () => {
    let bundleID = cspDetails?.cspDetails?.product?.filter((record) => record.TechRefId === techRefID.value)[0]?.BundleId;
    let mmidRecord = {
      id: existingItemDetails?.id ? existingItemDetails?.id : generate(),
      mId: mId,
      materialId: materialId.value,
      itemDescription: itemDescription.value ? itemDescription.value : null,
      upsell: upsell.value === "Yes" ? true : false,
      upsellForUI: upsell?.value,
      type: type.value ? type.value : null,
      displayType: getFullName(TYPE_DROPDOWN_VALUES, type.value),
      orderType: orderType.value ? orderType.value : null,
      techRefID: upsell.value === "Yes" ? "" : techRefID.value,
      contractItem: contractItem.value,
      feature: feature?.value?.map((id) => id.value),
      sapId: selectedContractItem.sapId ? selectedContractItem.sapId : existingItemDetails.sapId,
      contractId: selectedContractItem.contractId ? selectedContractItem.contractId : existingItemDetails.sapIdVal,
      validMMID: true,
      vinMaterialFeatures: vinMaterialFeaturesList.length ? vinMaterialFeaturesList : existingItemDetails.vinMaterialFeatures,
      vmfIdList: existingItemDetails?.vmfIdList ? existingItemDetails.vmfIdList : [],
      bundleId: bundleID || "",
    };
    if (existingItemDetails) {
      if (!_.isEqual(existingItemDetails, mmidRecord)) {
        handleVehicle("edited", mmidRecord);
      }
    } else {
      handleVehicle("added", mmidRecord);
    }
    let newVehicleMMIDList = _.cloneDeep(vehicleMMIDTableList).filter((record) => record.id !== mmidRecord.id);
    setVehicleMMIDTableList([mmidRecord, ...newVehicleMMIDList]);
    setAddVehicleMMIDModal(false);
  };
  const fetchFeatureList = async () => {
    setLoader(true);
    let response = await getFeatureList("VIN");
    if (response?.data?.statusCode === "200" && response?.data) {
      let data = response.data.data;
      const sortedFeatures = data.sort((a, b) => (a.feature > b.feature ? 1 : -1));
      setfeatureValues(sortedFeatures);
      setLoader(false);
    } else {
      setLoader(false);
      notify("Error occurred while fetching Feature List. Please try again", "error", false);
    }
  };

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(
      <>
        <CustomNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />
      </>
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const onContractItemClick = async () => {
    setLoader(true);
    let response = await getSapIdList(cspDetails?.cspDetails?.salesOrgId);
    if (response?.status === 200) {
      response = response?.data?.data;
      let prodSapIdArr = [];
      if (response.length > 0) {
        cspDetails &&
          cspDetails?.cspDetails?.cspData?.data?.data?.Product.map((prodSapId) => {
            let cspProduct = response?.filter((sapInfo) => sapInfo.sapId === prodSapId.SAPProductID);
            if (cspProduct && cspProduct[0] != null && !prodSapIdArr?.includes(cspProduct[0]))
              prodSapIdArr.push({ ...cspProduct[0], orderType: prodSapId.OrderDetailType });
          });
        if (orderType.value === "") {
          setContractItemList([]);
        } else {
          setContractItemList(prodSapIdArr);
        }
        setContractItems(prodSapIdArr);
        if (existingItemDetails && Object.keys(existingItemDetails).length) {
          handleFields("orderType", existingItemDetails?.orderType);
          handleFields("contractItem", existingItemDetails?.contractItem);
          response.map((item) => {
            item.contractName === existingItemDetails?.contractItem && setSelectedContractItem(item);
          });
        }
      } else {
        notify("There is no Contract Item for the sales org", "error", false);
      }
    } else {
      notify("We are currently experiencing issues, please try again", "error", false);
    }
    setLoader(false);
  };

  const handleMMIDSearch = async () => {
    // if (materialId.success && contractItem.success && orderType.success) {
    if (!isSearchDisabled) {
      setLoader(true);
      let data;
      if (upsell.value === "Yes" && orderType.value === "Upsell") {
        data = {
          orderType: orderType.value,
          salesOrgId: cspDetails?.cspDetails?.salesOrgId,
          sapId: "",
          mmId: materialId.value,
          governmentAcc: isGovernmentAcc.isGovernmentAcc,
          contractOverride: true,
          processFlow: "Shipment",
        };
      } else {
        data = {
          orderType: orderType.value,
          salesOrgId: cspDetails?.cspDetails?.salesOrgId,
          sapId: selectedContractItem.sapId,
          mmId: materialId.value,
          governmentAcc: isGovernmentAcc.isGovernmentAcc,
          contractOverride: false,
          processFlow: "Shipment",
        };
      }
      let response = await materialIdSearch(data, isSourceCSP.isSourceCSP);
      if (response?.status === 200) {
        response = response?.data?.data;
        if (response.mmidValid) {
          if (response.materialContract.length) {
            handleFields("itemDescription", response.materialName);
            setMId(response.id);
            setIsFieldDisabled(false);
            setIsMMIDValidated(true);
            setType({ value: response.defaultType, error: false, errorText: "", success: true });
          } else {
            notify("Invalid MMID", "error", false);
          }
          handleTechRefId(orderType.value, selectedContractItem.sapId);
        } else {
          notify("Invalid MMID", "error", false);
        }
      } else {
        notify("We are currently experiencing issues, please try again", "error", false);
      }
      setLoader(false);
    }
  };

  const handleTechRefId = (orderType, contractItem) => {
    let productArray = cspDetails?.cspDetails?.product;
    let techRefList = [];
    if (productArray?.length > 0) {
      productArray.map((item) => {
        item?.OrderDetailType?.toLowerCase() === orderType?.toLowerCase() && item?.SAPProductID === contractItem && techRefList.push(item?.TechRefId);
      });
      techRefList.includes(techRefID.value) ? handleFields("techRefID", techRefID.value) : techRefID.value && handleFields("techRefID", "");
    }
    if (existingItemDetails && Object.keys(existingItemDetails).length) {
      let techRefVal = techRefID.value ? techRefID.value : existingItemDetails?.techRefID;
      techRefList.includes(techRefVal) ? handleFields("techRefID", techRefVal) : handleFields("techRefID", "");
    }
    setFilteredTechRefIdList(techRefList);
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    selectAllCheckbox?.after(" Select All");
  };

  useEffect(() => {
    if (existingItemDetails && existingItemDetails?.vinMaterialFeatures?.length) {
      // console.log("existingItemDetails modal", existingItemDetails);

      let mmidTableListExisting = existingItemDetails?.feature?.map((records) => records);
      let featureListToEdit = featureValues?.filter((item) => mmidTableListExisting?.includes(item.id));

      let featOptionList = featureListToEdit?.map((data) => {
        return { label: data.feature, value: data.id };
      });

      handleFields("feature", featOptionList);
    }
  }, [existingItemDetails, featureValues]);

  /**
   * This section of code is responsible for handling the effect of changing the order type value.
   * It updates the contract item list based on the selected order type value.
   * The useEffect hook is used to trigger this effect whenever the order type value changes.
   * It filters the contractItems array to get only the contract items that have the same order type as the selected order type value.
   * Then, it removes any duplicate contract items from the filtered list.
   * The resulting unique contract item objects are set as the new contractItemList state.
   */
  useEffect(() => {
    let updatedContractItemList = contractItems.filter((contractItem) => contractItem.orderType === orderType.value);
    const uniqueContractItemObj = Array.from(new Set(updatedContractItemList.map((obj) => JSON.stringify(obj)))).map((str) => JSON.parse(str));
    setContractItemList(uniqueContractItemObj);
  }, [orderType.value]);

  return (
    <>
      <Spacer top="10px" />
      <DialogModal
        visible={true}
        onHide={() => setAddVehicleMMIDModal(false)}
        focusOnShow={true}
        opened={addVehicleMMIDModal}
        header={
          <FlexRow justifyContent="space-between" style={{ padding: "15px" }}>
            <div>
              <Title size="medium">Add Item</Title>
            </div>
          </FlexRow>
        }
        // style={{ width: "65vw", height: "100%", overflowY: "auto" }}
        style={{ width: "65vw", overflowY: "auto" }}
      >
        <ModalBody css={{ padding: "16px 0 16px 16px !important", overflowY: "inherit", clear: "both" }}>
          {notification}
          <Spacer top="1rem" />
          <FlexRow>
            <CustomDropdown
              width="50%"
              type="text"
              label="Order Type"
              aria-label="Order Type"
              value={orderType.value ? orderType.value : "Please select"}
              error={orderType.error}
              errorText={orderType.errorText}
              success={orderType.success}
              css={{ paddingBottom: "0.6rem" }}
              onChange={(e) => handleFields("orderType", e.target.value)}
              paddingRight="5px"
            >
              <>
                <option disabled>Please select</option>
                {orderTypeOptions.map((type) => (
                  <option key={type}>{type}</option>
                ))}
              </>
            </CustomDropdown>

            {orderType?.value?.toLowerCase() === "upsell" && (
              <CustomDropdown
                width="50%"
                type="text"
                label="Contract Override"
                aria-label="Contract Override"
                value={upsell.value ? upsell.value : "Please select"}
                //error={upsell.error}
                errorText={upsell.errorText}
                success={upsell.success}
                onChange={(e) => handleFields("upsell", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
                // disabled={isFieldDisabled}
              >
                <option disabled>Please select</option>
                <option>Yes</option>
                <option>No</option>
              </CustomDropdown>
            )}

            {!isUpsell && (
              <CustomDropdown
                width="50%"
                type="text"
                label="Contract Item (SAP ID)"
                aria-label="Contract Item (SAP ID)"
                value={contractItem.value ? contractItem.value : "Please select"}
                error={contractItem.error}
                errorText={contractItem.errorText}
                success={contractItem.success}
                // onClick={!contractItemList.length > 0 && onContractItemClick}
                onChange={(e) => handleFields("contractItem", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              >
                <option disabled>Please select</option>
                {contractItemList &&
                  contractItemList.map((list, index) => (
                    <option key={list.contractId} value={list.contractName}>
                      {list.contractName}
                    </option>
                  ))}
              </CustomDropdown>
            )}

            <CustomInput
              formWidth="50%"
              type="text"
              label="MMID"
              aria-label="MMID"
              value={materialId.value}
              error={materialId.error}
              errorText={materialId.errorText}
              success={materialId.success}
              onChange={(e) => handleFields("MMID", e.target.value)}
              paddingRight="5px"
              maxLength={10}
              css={{ paddingBottom: "0.6rem" }}
            />
            {/* <img
              src={searchIcon}
              css={{ height: "25px", width: "25px", display: "flex", alignSelf: "center", marginLeft: "10px", cursor: "pointer" }}
              onClick={handleMMIDSearch}
            ></img> */}
            <CustomSLButton
              aria-label="Search"
              role="button"
              css={orderType.value === "Upsell" && !isUpsell ? { margin: "10px 20px 10px 0px" } : { margin: "19px 20px 10px 0px" }}
              disabled={isSearchDisabled}
              aria-disabled={isSearchDisabled}
              primary
              onClick={handleMMIDSearch}
            >
              Search
            </CustomSLButton>
          </FlexRow>
          <CustomInput
            width="100%"
            type="text"
            label="Item Description"
            aria-label="Item Description"
            value={itemDescription.value}
            error={itemDescription.error}
            errorText={itemDescription.errorText}
            success={itemDescription.success}
            disabled={true}
            onChange={(e) => handleFields("itemDescription", e.target.value)}
            paddingRight="5px"
          />
          <FlexRow>
            {!isUpsell && (
              <CustomDropdown
                width="50%"
                type="text"
                label="Tech Ref ID"
                aria-label="Tech Ref ID"
                value={techRefID.value ? techRefID.value : "Please select"}
                error={techRefID.error}
                errorText={techRefID.errorText}
                success={techRefID.success}
                onChange={(e) => handleFields("techRefID", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
                disabled={isFieldDisabled}
              >
                <option disabled>Please select</option>
                {filteredTechRefIdList.map((refId) => (
                  <option key={refId}>{refId}</option>
                ))}
              </CustomDropdown>
            )}
            <div style={{ width: "99.5%", marginBottom: "10px", paddingRight: "0.5%" }}>
              <SmallText css={{ marginTop: "0px" }} disabled={isFieldDisabled}>
                Feature
              </SmallText>
              <div tabIndex={0} aria-label="feature Enter key to select from list">
                <CustomisedInput
                  value={feature.value}
                  disabled={isFieldDisabled}
                  tabIndex={0}
                  error={feature.error}
                  errorText={feature.errorText}
                  success={feature.success}
                  onChange={(selected) => handleFields("feature", selected)}
                  disableSearch={true}
                  isCreatable={true}
                  labelledBy={"Feature"}
                  label="Feature"
                  aria-label="Feature"
                  options={featureValues.map((data) => {
                    return { label: data.feature, value: data.id };
                  })}
                />
              </div>
            </div>
            <Loader active={isLoading} />
            <CustomDropdown
              width={orderType?.value?.toLowerCase() === "upsell" ? "50%" : "100%"}
              type="text"
              label="Type"
              aria-label="Type"
              value={type.value ? type.value : "Please select"}
              error={type.error}
              errorText={type.errorText}
              success={type.success}
              disabled={isFieldDisabled}
              onChange={(e) => handleFields("type", e.target.value)}
              paddingRight="5px"
            >
              <>
                <option disabled>Please select</option>
                {TYPE_DROPDOWN_VALUES.map((type) => (
                  <option key={type} value={type.code}>{`${type.code} - ${type.description}`}</option>
                ))}
              </>
            </CustomDropdown>
          </FlexRow>
          <FlexRow style={{ marginTop: "10px" }}>
            <CustomSLButton
              css={{ padding: "0 10px 0 10px" }}
              primary
              aria-label="Save"
              role="button"
              disabled={isSaveBtnDisabled}
              aria-disabled={isSaveBtnDisabled}
              onClick={() => {
                handleSaveVehicleMMID();
              }}
            >
              Save
            </CustomSLButton>
            <CustomSLButton aria-label="Cancel" role="button" secondary onClick={() => setAddVehicleMMIDModal(false)}>
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </DialogModal>
    </>
  );
};
